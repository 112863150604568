<template>
  <label>
    <p class="input_title" v-if="props.label">
      {{ $t(props.label) }}
      <Tooltip :text="props.tooltipText" :right="props.tooltipTextOnTheRight" />
      <Tooltip v-if="props.required" text="Required field" color="#e3001b" :right="props.tooltipTextOnTheRight" />
    </p>
    <div class="input_container">
      <input ref="refInput" v-model="value" :type="props.type" :class="inputClass" v-bind="$attrs" />

      <a v-if="props.actionIcon" class="input_container_action-icon" href="#" @click.prevent="onActionIconClick">
        <Icon class="input_container_action-icon_icon" height="24" width="24" :icon="props.actionIcon" />
      </a>
      <Icon v-else-if="props.icon" class="input_container_icon" fill="none" :icon="props.icon" />

      <span v-if="isError" class="input_error" :title="errorMsg" data-error-type="message">
        <template v-if="isNonTranslatedError">{{ isMinLengthError ? $tc(errorMsg, counter) : $t(errorMsg) }}</template>
        <template v-else>{{
          isMinLengthError ? $tc(errorMsg, counter) : $t(errorMsg || DEFAULT_INPUT_ERROR)
        }}</template>
      </span>
      <div v-if="props.processing" class="input_spinner">
        <SpinnerRadial />
      </div>
    </div>
  </label>
</template>

<script>
import { computed, onMounted, ref, watch } from "@vue/composition-api";

import Icon from "@/components/icons/Icon.vue";
import SpinnerRadial from "@/components/loaders/SpinnerRadial.vue";

import { ERRORS_MESSAGES, VALIDATION_ERRORS, validators } from "@/helpers/validators";
import Tooltip from "@/components/tooltips/Tooltip.vue";
import { t } from "@/helpers/i18n";

const DEFAULT_INPUT_ERROR = "Ошибка поля";

export default {
  components: {
    Icon,
    SpinnerRadial,
    Tooltip,
  },

  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },

  emits: ["action", "input", "update:modelValue", "validation-error"],

  props: {
    actionIcon: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    errorBorderColor: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
    },
    modelValue: {
      type: [Number, String],
      default: "",
    },
    minLength: {
      type: Number,
      default: 0,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    emitValidationErrorsById: {
      type: String,
      default: "",
    },
    tooltipText: {
      type: String,
    },
    tooltipTextOnTheRight: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    validator: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const refInput = ref(null);

    const error = ref(null);
    const changed = ref(false);

    const errorMsg = computed(() => {
      const message = changed.value ? null : ERRORS_MESSAGES[props.error] || props.error;
      return t(ERRORS_MESSAGES[error.value]) || message;
    });
    const isNonTranslatedError = computed(() => !!ERRORS_MESSAGES[props.error]);
    const isMinLengthError = computed(() => ERRORS_MESSAGES[props.error] === ERRORS_MESSAGES.min_length);
    const counter = computed(() => props.minLength);

    const isError = computed(() => Boolean(errorMsg.value));
    const value = computed({
      get() {
        return props.modelValue;
      },
      set(val) {
        if (props.validator) {
          error.value = validateValue(val);

          if (props.emitValidationErrorsById) {
            emit("validation-error", error.value, props.emitValidationErrorsById);
          }
        }

        changed.value = !error.value;

        emit("update:modelValue", val);
        emit("input", val);
      },
    });

    const inputClass = computed(() => ({
      error: isError.value,
      processing: props.processing,
      show_icon: !!props.icon || !!props.actionIcon,
      errorBorderColor: props.errorBorderColor,
    }));

    function validateValue(val) {
      const validatorName = props.validator;

      if (validators[validatorName]) {
        const validationError = validators[validatorName](val);

        return validationError || null;
      }
    }

    function onActionIconClick() {
      emit("action");
    }

    onMounted(() => {
      if (props.autofocus) {
        refInput.value?.focus();
      }
    });
    watch(
      () => props.error,
      () => {
        changed.value = false;
      }
    );

    return {
      VALIDATION_ERRORS,
      DEFAULT_INPUT_ERROR,

      refInput,

      props,

      errorMsg,
      isNonTranslatedError,
      isMinLengthError,
      counter,
      inputClass,
      isError,
      value,

      onActionIconClick,
    };
  },
};
</script>

<style lang="scss" scoped>
$input_height: 38px;

label {
  display: block;
  width: 100%;
  position: relative;

  .input_container {
    display: block;
    width: 100%;
    position: relative;
    padding-bottom: 24px;

    &_icon {
      position: absolute;
      top: calc($input_height / 2);
      right: 8px;
      transform: translateY(-50%);
      stroke: #e7e7e7;
    }

    &_action-icon {
      position: absolute;
      height: $input_height;
      width: $input_height;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &_icon {
        fill: #9c9c9c;
        display: block;

        &:hover {
          fill: #444;
        }
      }
    }

    input {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      height: $input_height;
      padding: 0 16px;
      font-weight: 400;
      font-size: 13px;
      color: #000;
      background-color: #fff;

      &[disabled] {
        background-color: rgba(231, 231, 231, 0.5);
        border-color: rgba(0, 0, 0, 0.3);
      }

      &:focus {
        border-color: #000;

        & + .input_container_icon {
          stroke: #ccc;
        }
      }

      &.error {
        border-color: #e3001b;
      }

      &.processing {
        padding-right: 24px;
      }

      &.show_icon {
        padding-right: 32px;
      }

      &.errorBorderColor {
        border-color: #e3001b;
      }
    }

    .input_error {
      font-size: 13px;
      color: #e3001b;
      line-height: 24px;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      max-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
    }

    .input_spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .input_title {
    display: flex;
    gap: 6px;
    font-size: 18px;
    font-weight: 500;
    color: #444545;
    margin-bottom: 6px;
  }
}
</style>
