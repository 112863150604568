<template>
  <small-layout
    ><div id="demo">
      <h1>Cover table</h1>
      <div>
        <label for="started_at">Started</label>
        <input type="date" id="started_at" v-model="started_at" />
        <label for="finished_at">Finished</label>
        <input type="date" id="finished_at" v-model="finished_at" />
        <label for="subscription">Subscription model filter</label>
        <select v-model="subscriptionType" id="subscription">
          <option value="all">All (Limited, Unlimited)</option>
          <option value="limited">Limited</option>
          <option value="unlimited">Unlimited</option>
        </select>
        <button type="button" @click="fetchStats">Reload</button>
        <download-csv :data="source" class="btn btn-default"> Download Holders CSV </download-csv>
      </div>
      <table>
        <thead>
          <tr>
            <th v-for="head in columns" :key="head.key" :title="head.title" @click="sortBy(head.key)">
              {{ head.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in preparedCoverList">
            <td>{{ entry.address }}</td>
            <td>{{ entry.city }}</td>
            <td>{{ entry.district }}</td>
            <td>
              <router-link
                :to="{
                  name: isBelarusSelected ? 'by_supplier_update' : 'default_supplier_update',
                  params: {
                    supplier: entry.supplier_id,
                    ...(!isBelarusSelected && { country }),
                  },
                }"
              >
                {{ entry.name }}
              </router-link>
            </td>
            <td>{{ entry.attraction_types_ru }}</td>
            <td>{{ formatCurrency(entry.price) }}</td>
            <td>{{ formatCurrency(entry.copayment) }}</td>
            <td>{{ entry.attraction_name }}</td>
            <td>{{ entry.started_at }}</td>
            <td>{{ entry.levels }}</td>
            <td>{{ entry.limited_levels }}</td>
          </tr>
        </tbody>
      </table>
    </div></small-layout
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import JsonCSV from "vue-json-csv";
import buildCurrencyFormatter from "../middleware/buildCurrencyFormatter";
import SortMixin from "@/mixins/SortMixin";
let newStartedAt = new Date();
let newFinishedAt = new Date();
newFinishedAt.setMonth(newFinishedAt.getMonth() + 1);
newFinishedAt.setDate(newFinishedAt.getDate() - 1);
export default {
  components: {
    DownloadCsv: JsonCSV,
  },
  mixins: [SortMixin],
  data: function () {
    return {
      source: [],
      started_at: newStartedAt.toJSON().substr(0, 10),
      finished_at: newFinishedAt.toJSON().substr(0, 10),
      subscriptionType: "all",
    };
  },
  computed: {
    ...mapGetters({
      country: "auth/country",
    }),
    currencyFormat() {
      const formatter = buildCurrencyFormatter(this.country);
      return formatter.format;
    },
    isBelarusSelected() {
      return this.country === "by";
    },
    preparedCoverList() {
      const filterCondition = {
        limited: (item) => !!item.limited_levels,
        unlimited: (item) => !item.limited_levels,
        all: (item) => !!item,
      };

      return this.filteredSource.filter(filterCondition[this.subscriptionType]);
    },
  },
  methods: {
    formatCurrency(value) {
      return this.currencyFormat ? this.currencyFormat(value / 100) : value;
    },
    ...mapActions({
      cover_list: "attraction/cover_list",
    }),
    getValues(row) {
      return row.slice(1);
    },
    async fetchStats() {
      this.source = await this.cover_list({ started_at: this.started_at, finished_at: this.finished_at });
    },
    createColumns() {
      return [
        { key: "address", name: "Адрес", order: 1 },
        { key: "city", name: "City", order: 1 },
        { key: "district", name: "District", order: 1 },
        { key: "name", name: "Название", order: 1 },
        { key: "attraction_types_ru", name: "Вид услуги", order: 1 },
        {
          key: "price",
          name: "Вознаграждение",
          title:
            "Ориентировочная для услуги, пока нет прямой привязки между списком услуг на карте и в списки выбираемых услуг",
          order: 1,
        },
        { key: "copayment", name: "Доплата", order: 1 },
        { key: "attraction_name", name: "Имя услуги", order: 1 },
        { key: "started_at", name: "Начало с", order: 1 },
        { key: "levels", name: "Старые уровни", order: 1 },
        { key: "limited_levels", name: "Новые уровни", order: 1 },
      ];
    },
  },
  mounted() {
    this.fetchStats();
  },
};
</script>

<style scoped lang="scss">
th,
td {
  min-width: 120px;
  padding: 10px 20px;
}
.btn.btn-default {
  cursor: pointer;
  background-color: lightgray;
}
</style>
