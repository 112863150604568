<template>
  <div class="modal_wrapper">
    <div class="modal_background" @click.self="emit('close')"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  emits: ["close"],

  setup(props, { emit }) {
    return {
      emit,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal_wrapper {
  background-color: transparent;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_background {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
}
</style>
