const API_PORTAL_USERS_PREFIX = "/api/hr-list/by-company";
const API_JOURNAL_URLS_PREFIX = "/api/jrnl";
const API_ADMIN_PREFIX = "/admin";
const API_SUPPLIERS_PREFIX = "/api/suppliers";

/**
 * Helpers
 */
const getUrlWithPrefix = (url) =>
  url.toString().startsWith("/") ? url : `/${url}`;

const getApiJournalUrl = (url) =>
  `${API_JOURNAL_URLS_PREFIX}${getUrlWithPrefix(url)}`;

function getApiJournalAdminUrl(url) {
  const apiJournalUrl = getApiJournalUrl(API_ADMIN_PREFIX);
  const requestUrl = getUrlWithPrefix(url);

  return `${apiJournalUrl}${requestUrl}`;
}

const getQuery = (queryParams) => {
  const urlsSearchParams = new URLSearchParams(queryParams);
  return urlsSearchParams.toString();
};

/**
 * URLs
 */
export default {
  attractions: {
    getBySupplier: (supplierId) =>
      `${API_SUPPLIERS_PREFIX}${getUrlWithPrefix(supplierId)}/attractions`,
  },

  cards: {
    loadHolder: (params = {}) => {
      const LOAD_HOLDER_URL_PATH = "/api/helpdesk/load_holder_by";
      const DEFAULT_SEARCH_PARAMS = {
        card_id: "",
        holder: "",
      };
      const searchParams = { ...DEFAULT_SEARCH_PARAMS, ...params };
      const urlsSearchParams = new URLSearchParams(searchParams);
      const url = `${LOAD_HOLDER_URL_PATH}?${urlsSearchParams.toString()}`;

      return url;
    },
  },

  companies: {
    index: "/api/companies",
    default: "/api/companies/default",
    single: (companyId) => `/api/companies/${companyId}`,

    portalUsers: {
      create: (companyId) =>
        API_PORTAL_USERS_PREFIX + getUrlWithPrefix(companyId) + "/user",
      list: (companyId) =>
        API_PORTAL_USERS_PREFIX + getUrlWithPrefix(companyId),
      sms: (companyId, employeeId) =>
        API_PORTAL_USERS_PREFIX +
        getUrlWithPrefix(companyId) +
        `/sms_code/${employeeId}`,
      update: (companyId, portalUserId) =>
        API_PORTAL_USERS_PREFIX +
        getUrlWithPrefix(companyId) +
        `/user/${portalUserId}`,
      searchOne: (queryParams = {}) =>
        API_PORTAL_USERS_PREFIX + `/user/search_one?${getQuery(queryParams)}`,
    },
  },

  limits: {
    index: () => `/api/subscription-limits`,
    single: (limitId) => `/api/subscription-limits/${limitId}`,
    page: (pageNumber) => `/api/subscription-limits?page=${pageNumber}`,
  },

  config: {
    country: (countryCode) => `api/v2/by_country/${countryCode}/configs`,
    managers: "/api/user_managers",
    portal: "/api/hr-portal/v2/config",
  },

  countries: {
    index: "/api/countries",
    single: (countryId) => `/api/countries/${countryId}`,

    locations: {
      index: (countryId) => `/api/countries/${countryId}/locations`,
      single: (countryId, locationId) =>
        `/api/countries/${countryId}/locations/${locationId}`,
    },

    priceModels: {
      index: (countryId) => `/api/countries/${countryId}/price_models`,
      single: (countryId, priceModelId) =>
        `/api/countries/${countryId}/price_models/${priceModelId}`,
      page: (countryId, pageNumber) =>
        `/api/countries/${countryId}/price_models?page=${pageNumber}`,
    },

    sellers: {
      index: (countryId) => `/api/countries/${countryId}/sellers`,
      single: (countryId, sellerId) =>
        `/api/countries/${countryId}/sellers/${sellerId}`,
    },
  },

  coupons: {
    attractions: (couponsGroupId) =>
      getApiJournalAdminUrl(`coupon_groups/${couponsGroupId}/attractions `),
    createOne: (couponsGroupId) =>
      getApiJournalAdminUrl(`coupon_groups/${couponsGroupId}/coupons`),
    deleteCoupon: (couponId) => getApiJournalAdminUrl(`coupons/${couponId}`),
    deleteAttraction: (couponsGroupId, attractionId) =>
      getApiJournalAdminUrl(
        `coupon_groups/${couponsGroupId}/attractions/${attractionId}`
      ),
    import: (couponsGroupId) =>
      getApiJournalAdminUrl(`coupon_groups/${couponsGroupId}/coupons/import`),
    redeem: (couponsGroupId) =>
      getApiJournalAdminUrl(`coupon_groups/${couponsGroupId}/coupons`),
    search: (couponsGroupId) =>
      getApiJournalAdminUrl(`coupon_groups/${couponsGroupId}/coupons/search`),
    single: (couponId) => getApiJournalAdminUrl(`coupons/${couponId}`),
    updateCoupon: (couponsGroupId, couponId) =>
      getApiJournalAdminUrl(
        `coupon_groups/${couponsGroupId}/coupons/${couponId}`
      ),
    groups: {
      importHistory: {
        delete: (couponsImportId) =>
          getApiJournalAdminUrl(`coupons/import/history/${couponsImportId}`),
        list: (couponsGroupId) =>
          getApiJournalAdminUrl(
            `coupon_groups/${couponsGroupId}/coupons/import/history`
          ),
      },
      list: getApiJournalAdminUrl("coupon_groups"),
      getUsedCouponsCount: (couponsImportId) =>
        getApiJournalAdminUrl(
          `coupons/import/history/${couponsImportId}/used_coupons_count`
        ),
    },
  },

  integrations: {
    attractions: {
      supplier: (supplierId) =>
        `/api/integrations/akova/1.0/supplier/${supplierId}/attractions`,
      update: (supplierId, integrationAttractionId) =>
        `/api/integrations/akova/1.0/supplier/${supplierId}/attractions/${integrationAttractionId}`,
    },
  },

  map: {
    coordsDetails: (lng, lat) =>
      `http://api.tiles.mapbox.com/v4/geocode/mapbox.places-v1/${lng},${lat}.json?access_token=${process.env.VUE_APP_MAPBOX_ACCESS_TOKEN}`,
  },

  notifications: {
    search: (queryParams = {}) =>
      `/api/notifications/search?${getQuery(queryParams)}`,
    single: (notificationId) => `/api/notifications/${notificationId}`,
  },

  orders: {
    acts: {
      index: (orderId) => `/api/claim/companies/order/${orderId}/order_acts`,
      download: (actId) => `/api/claim/order_acts/${actId}/download`,
      single: (orderId, actId) =>
        `/api/claim/companies/order/${orderId}/order_acts/${actId}`,
    },
    invoices: {
      create: (orderId) =>
        `/api/claim/companies/order/${orderId}/order_invoices/new`,
      delete: (orderId, invoiceId) =>
        `/api/claim/companies/order/${orderId}/order_invoices/${invoiceId}`,
      download: (invoiceId) =>
        `/api/claim/claim_invoices/${invoiceId}/download`,
      list: (orderId) => `/api/claim/companies/order/${orderId}/order_invoices`,
      store: (orderId) =>
        `/api/claim/companies/order/${orderId}/order_invoices`,
      update: (orderId, invoiceId) =>
        `/api/claim/companies/order/${orderId}/order_invoices/${invoiceId}`,
    },
    accountant: {
      index: (date, sorting) =>
        `/api/order_list/accountant?month=${date}&sorting['direction']=${sorting.direction}&sorting['header']=${sorting.header}`,
      downloadAllActs: (date) =>
        `/api/order_list/accountant/download_acts?month=${date}`,
      generateActs: "/api/order_list/accountant/generate_acts",
      reviewOrders: "/api/order_list/accountant/review_orders",
      sendEmails: "/api/order_list/accountant/send_emails",
    },
    manager: {
      index: (date, sorting) =>
        `/api/order_list/manager?month=${date}&sorting['direction']=${sorting.direction}&sorting['header']=${sorting.header}`,
      setActivationDate: (orderId) => `/api/order_list/${orderId}/start-at`,
      cancelOrder: (orderId) => `/api/order_list/${orderId}/cancel`,
    },
  },

  subscribers: {
    audit: "/api/audits",
    auditDetails: (auditId) => `/api/audits/${auditId}`,
  },

  suppliers: {
    search: (params = {}) => {
      const DEFAULT_SEARCH_PARAMS = {
        matchByName: "",
      };
      const searchParams = { ...DEFAULT_SEARCH_PARAMS, ...params };
      const url = `${API_SUPPLIERS_PREFIX}?${getQuery(searchParams)}`;

      return url;
    },
    single: (supplierId) => `/api/suppliers/${supplierId}`,
  },

  templates: {
    create: "/api/templates",
    download: (templateId) => `/api/templates/${templateId}/download`,
    list: "/api/templates",
    single: (templateId) => `/api/templates/${templateId}`,
  },

  transactions: {
    list: "/api/bsb_payments?page=1&claim_id=&unn=&aggregator=",
  },

  utils: {
    unn: (unn) => `/api/unn_search?unn=${unn}`,
  },
};
