<template>
  <div class="container">
    <div class="row-header">
      <h1>Price Models</h1>
      <Button v-if="isConfigLoaded" @click="openPriceModelAddModal">Add</Button>
    </div>
    <ErrorBanner :message="errorMessage" />
    <SpinnerBrand v-if="processingMethods.GET" centered />
    <div v-if="isDataLoaded" class="content">
      <SimpleTable
        :actions="TABLE_ACTIONS"
        :cells="TABLE_CELLS"
        :headers="TABLE_HEADERS"
        :items="state.priceModelsList"
        show-actions-button
        @copy="openPriceModelCopyModal"
        @edit="openPriceModelEditModal"
        @delete="openPriceModelDeleteModal"
        centerTableData
      />
      <TablePagination
        v-model="state.page"
        :total-pages="state.totalPages"
        :pageChangeCallback="pageChangeCallback"
        class="pagination"
        centered
      />
    </div>
  </div>
</template>
<script>
import { ref, reactive, computed, onBeforeMount } from "@vue/composition-api";

import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";
import TablePagination from "@/components/tables/pagination/TablePagination.vue";

import { camelCaseObjectKeys } from "@/helpers/converters/convertObjectCaseType";
import { CONFIG_ACTION_FETCH_CONFIGS } from "@/store/modules/config";
import { MODAL_ACTION_OPEN, MODAL_MODES, MODAL_TYPES } from "@/store/modules/modal";
import store from "@/store";
import URLS from "@/config/urls";
import useRequest from "@/composables/network/useRequest";

const TABLE_HEADERS = [
  "Seller Partner",
  "Company",
  "Currency",
  "Subscription Model",
  "Pricing Policy",
  "Available subscriptions:",
];

const TABLE_CELLS = [
  (item) => item.seller?.name || "-",
  (item) => item.company?.name || "-",
  "currency",
  (item) => item.subscriptionModel,
  (item) => item.pricingPolicy.replaceAll("\n", "<br />"),
  ({ availableSubscriptions }) => availableSubscriptions.split(",").join(", "),
];

const TABLE_ACTIONS = [
  {
    title: "Copy",
    key: "copy",
    icon: {
      type: "copy",
      color: "#E3001B",
    },
  },
  {
    title: "Edit",
    key: "edit",
    icon: {
      type: "edit",
      color: "#0038FF",
    },
  },
  {
    title: "Delete",
    key: "delete",
    icon: {
      type: "delete",
      color: "#E3001B",
    },
  },
];

export default {
  components: {
    Button,
    ErrorBanner,
    SimpleTable,
    SpinnerBrand,
    TablePagination,
  },

  setup() {
    const { fetch, errorMessage, processingMethods } = useRequest();

    const isConfigLoaded = ref(true);

    const state = reactive({
      page: 1,
      totalPages: 1,
      totalPriceModels: 0,
      perPage: 0,
      priceModelsList: [],

      companies: [],
    });

    const countryId = computed(() => store.state.auth.country);
    const isDataLoaded = computed(() => state.priceModelsList.length > 0);

    function addNewPriceModel() {
      const pageNumber = Math.ceil((state.totalPriceModels + 1) / state.perPage);
      fetchPriceModelsListPage(pageNumber);
    }

    function updatePriceModel(updatedPriceModel) {
      const newPriceModelsList = Array.from(state.priceModelsList);
      const oldPriceModelIndex = newPriceModelsList.findIndex(({ id }) => id === updatedPriceModel.id);

      newPriceModelsList.splice(oldPriceModelIndex, 1, camelCaseObjectKeys(updatedPriceModel));
      state.priceModelsList = newPriceModelsList;
    }

    async function deletePriceModel(priceModelId) {
      const { error } = await fetch({
        url: URLS.countries.priceModels.single(countryId.value, priceModelId),
        method: "DELETE",
        omitData: true,
      });

      if (!error) {
        state.priceModelsList = state.priceModelsList.filter(({ id }) => id !== priceModelId);

        if (state.page > 1) {
          if (!state.priceModelsList.length) {
            state.page = state.page - 1;
          }
        }

        fetchPriceModelsListPage(state.page);
      }
    }

    function openPriceModelAddModal() {
      const type = MODAL_TYPES.priceModel;
      const mode = MODAL_MODES[type].full;

      store.dispatch(MODAL_ACTION_OPEN, {
        type,
        mode,
        payload: {
          onAccept: addNewPriceModel,
          companies: state.companies,
        },
      });
    }

    function openPriceModelCopyModal(copiedPriceModelId) {
      const type = MODAL_TYPES.priceModel;
      const mode = MODAL_MODES[type].copy;

      const { currency, pricingPolicy, subscriptionModel } = state.priceModelsList.find(
        ({ id }) => id === copiedPriceModelId
      );

      store.dispatch(MODAL_ACTION_OPEN, {
        type,
        mode,
        payload: {
          onAccept: addNewPriceModel,
          companies: state.companies,
          priceModel: {
            currency,
            pricingPolicy,
            subscriptionModel,
          },
        },
      });
    }

    function openPriceModelEditModal(priceModelId) {
      const priceModel = state.priceModelsList.find(({ id }) => id === priceModelId);
      const type = MODAL_TYPES.priceModel;
      const mode = MODAL_MODES[type].full;

      store.dispatch(MODAL_ACTION_OPEN, {
        type,
        mode,
        payload: {
          onAccept: updatePriceModel,
          priceModel,
          companies: state.companies,
        },
      });
    }

    function openPriceModelDeleteModal(id) {
      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.action,
        payload: {
          onAccept: deletePriceModel.bind(null, id),
        },
      });
    }

    function pageChangeCallback(newPage) {
      fetchPriceModelsListPage(newPage);
    }

    async function fetchPriceModelsListPage(page = 1) {
      state.priceModelsList = [];
      state.page = page;
      const url = URLS.countries.priceModels.page(countryId.value, page);
      const { data, error, meta } = await fetch({ url, metaFields: true });

      if (!error) {
        state.priceModelsList = data.map((priceModelObj) => camelCaseObjectKeys(priceModelObj));
        state.totalPages = meta.lastPage;
        state.totalPriceModels = meta.total;
        state.perPage = meta.perPage;
      }
    }

    async function getCompanies() {
      const { data, error } = await fetch({
        url: URLS.companies.index,
        traceKey: "CUSTOM",
      });

      if (!error) {
        state.companies = data;
      }
    }

    async function getConfig() {
      isConfigLoaded.value = false;
      const configsList = [{ country: [countryId.value] }, "portal"];

      await store.dispatch(CONFIG_ACTION_FETCH_CONFIGS, configsList);
      await getCompanies();

      isConfigLoaded.value = true;
    }

    onBeforeMount(() => {
      getConfig();
      fetchPriceModelsListPage();
    });

    return {
      TABLE_HEADERS,
      TABLE_CELLS,
      TABLE_ACTIONS,

      processingMethods,
      errorMessage,

      state,

      isDataLoaded,
      isConfigLoaded,

      openPriceModelAddModal,
      openPriceModelCopyModal,
      openPriceModelEditModal,
      openPriceModelDeleteModal,
      pageChangeCallback,
    };
  },
};
</script>
<style lang="scss" scoped>
.content {
  .pagination {
    margin-top: 48px;
  }
}
</style>
