<template>
  <fieldset>
    <update-visit
      v-if="visitUpdateHolder"
      :visitUpdateHolder="visitUpdateHolder"
      v-on:toggleForm="toggleUpdateVisit"
      :supplier_id="supplier_id"
      :attractions="filteredSource"
    ></update-visit>
    <button @click="toggleAddVisitModal">Добавить визит</button>
    <legend>Attractions</legend>

    <div v-if="source.length">
      <form id="search">Search <input name="query" v-model="filterKey" /></form>
      <table>
        <thead>
          <tr>
            <th v-for="item in columns" @click="sortBy(item.key)" :class="{ active: orderKey === item.key }">
              {{ item.name | capitalize }}
              <span class="arrow" :class="item.order > 0 ? 'asc' : 'dsc'"> </span>
            </th>
            <th>actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="attraction in filteredSource">
            <td>{{ attraction.name }}</td>
            <td>{{ attraction.list }}</td>
            <td>{{ attraction.levels }}</td>
            <td>{{ attraction.limited_levels }}</td>
            <td>{{ formatCurrency(attraction.price) }}</td>
            <td>
              <button @click="() => toggleScheduleForm(attraction, 'update')">Update</button>
              <button @click="() => toggleScheduleForm(attraction, 'delete')">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <button @click="() => toggleScheduleForm(newAttraction, 'create')">
      {{ attractionScheduleForm ? "Abort" : "Creating" }}
    </button>
    <fieldset v-if="attractionScheduleForm">
      <supplier-view-attraction-schedule-form
        :country="country"
        :attraction="scheduleAttraction"
        :command="scheduleCommand"
        :published_at="schedulePublishedAt"
        :activated_at="scheduleActivatedAt"
        :errors="attractionScheduleValidationErrors"
        @submit="fireCreateScheduleAttraction"
      >
      </supplier-view-attraction-schedule-form>
    </fieldset>
  </fieldset>
</template>

<script>
import ValidationErrors from "../ValidationErrors";
import { mapActions, mapGetters } from "vuex";
import SortMixin from "../../mixins/SortMixin";
import UpdateVisit from "../UpdateVisit";
import config from "../../config";
import CurrencyInput from "../CurrencyInput";
import buildCurrencyFormatter from "../../middleware/buildCurrencyFormatter";
import SupplierViewAttractionScheduleForm from "./SupplierViewAttractionScheduleForm";
import { formatToRFC3339 } from "../../middleware/functions";

export default {
  mixins: [SortMixin],
  components: {
    SupplierViewAttractionScheduleForm,
    CurrencyInput,
    UpdateVisit,
    ValidationErrors,
  },
  props: ["supplier_id", "supplier_appendix", "country"],
  data() {
    const formatter = buildCurrencyFormatter(this.country);
    const now = new Date();
    return {
      visitUpdateHolder: false,
      attraction: {
        list: "gym club",
      },
      scheduleCommand: "update",
      scheduleActivatedAt: formatToRFC3339(now, true),
      schedulePublishedAt: formatToRFC3339(now, true),
      scheduleAttraction: null,
      newAttraction: {
        name: "",
        levels: "G",
        model_value: 100,
        appendix: "",
        list: "gym club",
        list_array: ["gym club"],
      },
      updatingAttractionItem: null,
      attractionScheduleForm: false,
      attractionScheduleValidationErrors: "",
      attractions: config.supported_attractions,
      currency: formatter.currency,
      currencyFormat: formatter.format,
    };
  },
  computed: {
    ...mapGetters({
      source: "supplier/attraction_list",
    }),
    new_attraction_appendix: {
      get() {
        if (this.newAttraction && this.newAttraction.appendix) {
          return this.newAttraction.appendix;
        }
        if (this.supplier_appendix) {
          return this.supplier_appendix;
        }
        return "";
      },
      set(value) {
        this.newAttraction.appendix = value;
      },
    },
  },
  methods: {
    toggleScheduleForm(attraction, command) {
      const now = new Date();
      const day15 = new Date();
      if (day15.getDate() > 15) {
        day15.setMonth(day15.getMonth() + 1);
      }
      day15.setDate(15);
      switch (command) {
        case "delete":
          now.setMonth(now.getMonth() + 1);
          now.setDate(1);
          break;
      }
      this.schedulePublishedAt = formatToRFC3339(day15);
      this.scheduleActivatedAt = formatToRFC3339(now);
      this.scheduleCommand = command;
      this.scheduleAttraction = attraction;
      this.scheduleAttraction.supplier_id = this.supplier_id;
      this.attractionScheduleForm = !this.attractionScheduleForm;
    },
    formatCurrency(value) {
      return this.currencyFormat ? this.currencyFormat(value / 100) : value;
    },
    toggleUpdateVisit(isShown) {
      this.visitUpdateHolder = isShown;
    },
    createColumns() {
      return [
        { key: "name", name: "Name", order: 1 },
        { key: "list", name: "Виды услуг", order: 1 },
        { key: "levels", name: "Levels", order: 1 },
        { key: "limited_levels", name: "New Levels", order: 1 },
        { key: "price", name: "Вознаграждение", order: 1 },
      ];
    },
    toggleAddVisitModal() {
      this.visitUpdateHolder = !this.visitUpdateHolder;
      this.visitUpdateHolderDate = new Date();
    },
    ...mapActions({
      getAttractionList: "supplier/load_attraction_list",
      createScheduleAttraction: "supplier/schedule_attraction",
    }),
    fireCreateScheduleAttraction(attraction) {
      attraction.list = attraction.list_array ? attraction.list_array.join(",") : "";
      this.attractionScheduleValidationErrors = this.createScheduleAttraction(attraction);
      if (!this.attractionScheduleValidationErrors) {
        this.attractionScheduleForm = false;
      }
    },
  },
  watch: {
    filteredSource: {
      handler(newFilteredSource) {
        const newFilteredSourceLevelsUsed = [];
        newFilteredSource.forEach((attraction) => {
          if (attraction.levels.length > 1) {
            const levelsToArray = attraction.levels.split("");
            newFilteredSourceLevelsUsed.push(...levelsToArray);
          } else {
            newFilteredSourceLevelsUsed.push(attraction.levels);
          }
        });
        this.$emit("require-rule", newFilteredSourceLevelsUsed);
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getAttractionList(this.supplier_id);
  },
};
</script>

<style scoped></style>
