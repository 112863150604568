<template>
  <div class="container">
    <div class="row-header">
      <h1>Limit Rules</h1>
      <Button v-if="isConfigLoaded" @click="addCustomLimitModal">Add</Button>
    </div>
    <ErrorBanner :message="errorMessage" />
    <SpinnerBrand v-if="processingMethods.GET" centered />
    <div v-if="isDefaultLimitLoaded" class="limit-rules-content">
      <SimpleTable
        :actions="DEFAULT_TABLE_ACTIONS"
        :cells="DEFAULT_TABLE_CELLS"
        :headers="DEFAULT_TABLE_HEADERS"
        :items="state.defaultLimitRule"
        show-actions-button
        @edit="editDefaultLimitModal"
      />
    </div>
    <div v-else class="empty-state">
      <h1>There's no a default limit rule</h1>
    </div>
    <div v-if="isCustomLimitsLoaded" class="limit-rules-content">
      <SimpleTable
        :actions="CUSTOM_TABLE_ACTIONS"
        :cells="CUSTOM_TABLE_CELLS"
        :headers="CUSTOM_TABLE_HEADERS"
        :items="state.customLimitRules"
        show-actions-button
        @edit="editCustomLimitModal"
        @delete="deleteCustomLimitModal"
        class="custom-limit-rules"
      />
      <TablePagination
        v-model="state.page"
        :total-pages="state.totalPages"
        :pageChangeCallback="pageChangeCallback"
        class="pagination"
        centered
      />
    </div>
    <div v-else class="empty-state">
      <h3>There're no custom limits</h3>
    </div>
  </div>
</template>
<script>
import { ref, reactive, computed, onBeforeMount } from "@vue/composition-api";

import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";
import TablePagination from "@/components/tables/pagination/TablePagination.vue";

import { camelCaseObjectKeys } from "@/helpers/converters/convertObjectCaseType";
import { CONFIG_ACTION_FETCH_CONFIGS } from "@/store/modules/config";
import { MODAL_ACTION_OPEN, MODAL_MODES, MODAL_TYPES } from "@/store/modules/modal";
import store from "@/store";
import URLS from "@/config/urls";
import useRequest from "@/composables/network/useRequest";

const DEFAULT_TABLE_HEADERS = ["Default Limit rule", "P+", "V+"];

const DEFAULT_TABLE_CELLS = [
  () => "Default Limit rule",
  ({ limits }) => limits.premium.visitLimits,
  ({ limits }) => limits.vip.visitLimits,
];

const DEFAULT_TABLE_ACTIONS = [
  {
    title: "Edit",
    key: "edit",
    icon: {
      type: "edit",
      color: "#0038FF",
    },
  },
];

const CUSTOM_TABLE_HEADERS = ["Company name", "P+", "V+"];

const CUSTOM_TABLE_CELLS = [
  ({ company }) => company.name,
  ({ limits }) => limits.premium.visitLimits,
  ({ limits }) => limits.vip.visitLimits,
];

const CUSTOM_TABLE_ACTIONS = [
  {
    title: "Edit",
    key: "edit",
    icon: {
      type: "edit",
      color: "#0038FF",
    },
  },
  {
    title: "Delete",
    key: "delete",
    icon: {
      type: "delete",
      color: "#E3001B",
    },
  },
];

export default {
  components: {
    Button,
    ErrorBanner,
    SimpleTable,
    SpinnerBrand,
    TablePagination,
  },

  setup() {
    const { fetch, errorMessage, processingMethods } = useRequest();

    const isConfigLoaded = ref(true);
    const state = reactive({
      page: 1,
      totalPages: 1,
      totalLimitRules: 0,
      perPage: 10,
      defaultLimitRule: [],
      customLimitRules: [],
      companies: [],
    });

    const countryId = computed(() => store.state.auth.country);
    const isDefaultLimitLoaded = computed(() => state.defaultLimitRule.length > 0);
    const isCustomLimitsLoaded = computed(() => state.customLimitRules.length > 0);

    async function fetchLimitRules(page = 1) {
      state.page = page;
      const url = URLS.limits.page(page);
      const { data, error, meta } = await fetch({ url, metaFields: true });

      if (!error) {
        const defaultLimitRule = data.default;
        state.defaultLimitRule = [defaultLimitRule].map(camelCaseObjectKeys);
        state.customLimitRules = data.custom?.map(camelCaseObjectKeys);
        state.totalPages = meta?.lastPage || 1;
        state.totalLimitRules = meta?.total || 1;
        state.perPage = meta?.perPage || 10;
      }
    }

    async function getCompanies() {
      const { data, error } = await fetch({
        url: URLS.companies.index,
        traceKey: "CUSTOM",
      });

      if (!error) {
        state.companies = data;
      }
    }

    function addCustomLimitModal() {
      const type = MODAL_TYPES.limitRuleModal;
      const mode = MODAL_MODES[type].add;

      store.dispatch(MODAL_ACTION_OPEN, {
        type,
        mode,
        payload: {
          onAccept: addNewLimitRule,
          companies: state.companies,
        },
      });
    }

    function addNewLimitRule() {
      const pageNumber = Math.ceil((state.totalLimitRules + 1) / state.perPage);
      fetchLimitRules(pageNumber);
    }

    function editDefaultLimitModal() {
      const type = MODAL_TYPES.limitRuleModal;
      const mode = MODAL_MODES[type].editDefault;
      const [{ limits, id }] = state.defaultLimitRule;

      store.dispatch(MODAL_ACTION_OPEN, {
        type,
        mode,
        payload: {
          onAccept: updateDefaultLimitRule,
          limits,
          limitId: id,
          companies: state.companies,
        },
      });
    }

    function updateDefaultLimitRule(updatedLimitRuleData) {
      state.defaultLimitRule = [updatedLimitRuleData].map(camelCaseObjectKeys);
    }

    function updateCustomLimitRule(updatedLimitRuleData) {
      const updatedCustomLimitRules = [...state.customLimitRules];
      const updatedCustomLimitRuleIndex = updatedCustomLimitRules.findIndex(({ id }) => id === updatedLimitRuleData.id);

      updatedCustomLimitRules.splice(updatedCustomLimitRuleIndex, 1, camelCaseObjectKeys(updatedLimitRuleData));
      state.customLimitRules = updatedCustomLimitRules;
    }

    function editCustomLimitModal(customLimitId) {
      const type = MODAL_TYPES.limitRuleModal;
      const mode = MODAL_MODES[type].edit;
      const [customLimitRule] = state.customLimitRules.filter(({ id }) => id === customLimitId);
      const { limits, id, company } = customLimitRule;

      store.dispatch(MODAL_ACTION_OPEN, {
        type,
        mode,
        payload: {
          onAccept: updateCustomLimitRule,
          limits,
          limitId: id,
          selectedCompany: company.id,
          companies: state.companies,
        },
      });
    }

    function deleteCustomLimitModal(id) {
      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.action,
        payload: {
          onAccept: deleteCustomLimit.bind(null, id),
        },
      });
    }

    async function deleteCustomLimit(customLimitId) {
      const { error } = await fetch({
        url: URLS.limits.single(customLimitId),
        method: "DELETE",
        omitData: true,
      });

      if (!error) {
        state.customLimitRules = state.customLimitRules.filter(({ id }) => id !== customLimitId);

        if (state.page > 1) {
          if (!state.customLimitRules.length) {
            state.page = state.page - 1;
          }
        }

        await fetchLimitRules(state.page);
      }
    }

    function pageChangeCallback(newPage) {
      fetchLimitRules(newPage);
    }

    async function getConfig() {
      isConfigLoaded.value = false;
      const configsList = [{ country: [countryId.value] }, "portal"];

      await store.dispatch(CONFIG_ACTION_FETCH_CONFIGS, configsList);
      await getCompanies();

      isConfigLoaded.value = true;
    }

    onBeforeMount(() => {
      getConfig();
      fetchLimitRules();
    });

    return {
      DEFAULT_TABLE_HEADERS,
      DEFAULT_TABLE_CELLS,
      DEFAULT_TABLE_ACTIONS,
      CUSTOM_TABLE_ACTIONS,
      CUSTOM_TABLE_HEADERS,
      CUSTOM_TABLE_CELLS,

      processingMethods,
      errorMessage,

      state,

      isCustomLimitsLoaded,
      isDefaultLimitLoaded,
      isConfigLoaded,

      editDefaultLimitModal,
      addCustomLimitModal,
      editCustomLimitModal,
      deleteCustomLimitModal,

      pageChangeCallback,
    };
  },
};
</script>
<style lang="scss">
.container {
  .empty-state {
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .row-header {
    padding: 0 10px;
  }
  .limit-rules-content {
    .table-simple tbody td:first-child {
      width: 70%;
    }

    .custom-limit-rules {
      margin-top: 48px;
    }
    .pagination {
      margin-top: 48px;
    }
  }
}
</style>
