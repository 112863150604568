import Vue from "vue";
import VueRouter from "vue-router";

import Helpdesk from "./components/Helpdesk.vue";
import SupportHelpdesk from "./components/support/Helpdesk";
import SupportHelpdeskHolder from "./components/support/HelpDeskHolder";
import SupportHolder from "./components/support/Holder";
import SupportView from "./components/support/View.vue";
import SearchCard from "./components/SearchCard.vue";
import SearchVisit from "./components/SearchVisit.vue";
import VisitJournal from "./components/VisitJournal.vue";
import CompanyCreate from "./components/companies/CompanyCreate.vue";
import CompanyUpdate from "./components/companies/CompanyUpdate.vue";
import CompanyView from "./components/companies/CompanyView.vue";
import CardIndex from "./components/CardIndex.vue";
import ClaimCompanyIndex from "./components/claims/CompanyIndex.vue";
import OrderUpdate from "./components/claims/OrderUpdate.vue";
import ClaimCardIndex from "./components/claims/CardIndex.vue";
import CardView from "./components/CardView.vue";
import SupplierIndex from "./components/suppliers/SupplierIndex.vue";
import SupplierQrCodeIndex from "./components/holder/Print";
import SupplierCoverIndex from "./components/suppliers/SupplierCoverIndex.vue";
import AttractionCoverIndex from "./components/AttractionCoverIndex.vue";
import PaymentIndex from "./components/PaymentIndex.vue";
import SupplierCoverageIndex from "./components/suppliers/SupplierCoverageIndex.vue";
import SupplierActIndex from "./components/suppliers/SupplierActIndex.vue";
import SupplierView from "./components/suppliers/SupplierView.vue";
import UserIndex from "./components/UserIndex.vue";
import ElectronicMailIndex from "./components/ElectronicMailIndex.vue";
import Login from "./components/passport/Login";
import AuditIndex from "./components/AuditIndex";
import TokenIndex from "./components/TokenIndex";
import TokenHistoryIndex from "./components/TokenHistoryIndex";
import HelpdeskPanelControl from "./components/support/PanelControl";
import HelpdeskAffiliatesUpdates from "./components/support/AffiliatesUpdates";

import BySupplierCreate from "./components/suppliers/by/SupplierCreate.vue";
import BySupplierUpdate from "./components/suppliers/by/SupplierUpdate.vue";
import AmSupplierCreate from "./components/suppliers/am/SupplierCreate.vue";
import AmSupplierUpdate from "./components/suppliers/am/SupplierUpdate.vue";
import SupplierScheduleIndex from "./components/suppliers/SupplierScheduleIndex";
import ForgotPassword from "./components/passport/ForgotPassword";
import ResetPassword from "./components/passport/ResetPassword";
import Settings from "./components/passport/Settings";

import CityList from "./components/holder/cities/Index";
import TicketList from "./components/holder/tickets/Index";
import TicketShow from "./components/holder/tickets/Show";
import TicketCreate from "./components/holder/tickets/Create";
import TicketStats from "./components/holder/tickets/Stats";
import SignUp from "./components/passport/SignUp";
import ClientCrud from "./components/companies/ClientCrud";
import ShowBounds from "./components/map/ShowBounds";

/**
 * new layouts and views
 */

import CommonLayout from "@/layouts/CommonLayout.vue";
import RouterView from "@/views/RouterView.vue";

import PortalUsersView from "@/views/companies/PortalUsersView.vue";

import CompanyEditView from "@/views/companies/CompanyEditView.vue";
import CompaniesListView from "@/views/companies/CompaniesListView.vue";

import CountriesListView from "@/views/countries/CountriesListView.vue";
import CountryEditView from "@/views/countries/CountryEditView.vue";

import CouponsListView from "@/views/coupons/CouponsListView.vue";
import CouponsImportView from "@/views/coupons/CouponsImportView.vue";
import CouponRedeemView from "@/views/coupons/CouponRedeemView.vue";
import CouponLinkAttractionsView from "@/views/coupons/LinkAttractionsView.vue";
import CouponsSearchView from "@/views/coupons/CouponsSearchView.vue";
import CouponsImportHistory from "@/views/coupons/CouponsImportHistoryView.vue";

import SubscriberAuditView from "@/views/subscribers/SubscriberAuditView.vue";
import SubscriberAuditDetailsView from "@/views/subscribers/SubscriberAuditDetailsView.vue";

import SupplierManageIntegration from "@/views/suppliers/ManageIntergration.vue";

import TemplatesListView from "@/views/templates/TemplatesListView.vue";
import TemplateEditView from "@/views/templates/TemplateEditView.vue";

import TransactionsListView from "@/views/transactions/TransactionsListView";

import NotificationsListView from "@/views/notifications/NotificationsListView.vue";
import NotificationView from "@/views/notifications/NotificationView.vue";

import OrderInvoicesView from "@/views/orders/OrderInvoicesView.vue";
import OrderActsView from "@/views/orders/OrderActsView.vue";
import OrdersListView from "@/views/orders/OrdersListView.vue";

import PriceModelsListView from "@/views/priceModels/PriceModelsListView.vue";
import JournalOfVisitsView from "@/views/visits/JournalOfVisitsView.vue";
import VisitCorrectionsListView from "@/views/visits/VisitCorrectionsListView.vue";
import LimitRulesListView from "@/views/limitRules/LimitRulesListView.vue";

export const ROUTES_NAMES = {
  companies: {
    index: "companies/index",
    add: "companies/add",
    edit: "companies/edit",
    list: "companies/list",
    portalUsers: "companies/portal-users",
  },
  countries: {
    index: "countries/index",
    list: "countries/list",
    add: "countries/add",
    edit: "countries/edit",
  },
  coupons: {
    index: "coupons/index",
    list: "coupons/list",
    import: {
      index: "coupons/import/index",
      view: "coupons/import/view",
      history: "coupons/import/history",
    },
    redeem: "coupons/redeem",
    attractions: "coupons/attractions",
    search: "coupons/search",
  },
  notifications: {
    list: "notifications/list",
    view: "notifications/view",
  },
  orders: {
    acts: {
      list: "orders/acts/list",
    },
    invoices: {
      list: "orders/invoices/list",
    },
    list: "orders/list",
  },
  price_models: {
    list: "price_models/list",
  },
  limit_rules: {
    list: "limit_rules/list",
  },
  subscribers: {
    audit: "subscribers/audit",
    auditDetails: "subscriber/auditDetails",
  },
  suppliers: {
    list: "suppliers/index",
    index: "supplier_list",
    integrations: {
      manage: "suppliers/integrations/manage",
    },
  },
  templates: {
    add: "templates/add",
    edit: "templates/edit",
    index: "templates/index",
    list: "templates/list",
  },
  transactions: {
    list: "transactions/list",
  },
  visits: {
    corrections: "visits/corrections",
    journal: "/visits/journal",
  },
};

Vue.use(VueRouter);

let routes = [
  {
    name: "show_bounds",
    path: "/helpdesk/show_bounds/visit/:visit_id",
    component: ShowBounds,
  },
  {
    name: "helpdesk_affiliates_updates",
    path: "/helpdesk/affiliates_updates",
    component: HelpdeskAffiliatesUpdates,
  },
  {
    name: "helpdesk_panel_control",
    path: "/helpdesk/panel_control",
    component: HelpdeskPanelControl,
  },
  {
    name: "token_history_list",
    path: "/history_tokens/list/:user_id/:token",
    component: TokenHistoryIndex,
  },
  {
    name: "token_history_list",
    path: "/history_tokens/list",
    component: TokenHistoryIndex,
  },
  {
    name: "token_list",
    path: "/tokens/list",
    component: TokenIndex,
  },
  {
    name: "audit_list",
    path: "/audits/list",
    component: AuditIndex,
  },
  {
    name: "payments_list",
    path: "/payments/list",
    component: PaymentIndex,
  },
  {
    name: "payments_list_params",
    path: "/payments/list/:aggregator/:unn/:claim_id",
    component: PaymentIndex,
  },
  {
    name: "visit_journal",
    path: "/",
    component: VisitJournal,
  },
  {
    name: "search_card_company",
    path: "/search_card/com/:company_id/:started_at/:finished_at",
    component: SearchCard,
  },
  {
    name: "search_card_card",
    path: "/search_card/card/:card_id/:started_at/:finished_at",
    component: SearchCard,
  },
  {
    name: "search_visit_supplier",
    path: "/search_visit/sup/:supplier_id/:started_at/:finished_at",
    component: SearchVisit,
  },
  {
    name: "search_visit_company",
    path: "/search_visit/com/:company_id/:started_at/:finished_at",
    component: SearchVisit,
  },
  {
    name: "search_visit_card",
    path: "/search_visit/card/:card_id/:started_at/:finished_at",
    component: SearchVisit,
  },
  {
    name: "helpdesk",
    path: "/helpdesk/index",
    component: Helpdesk,
  },
  {
    name: "helpdesk_mobile",
    path: "/helpdesk/mobile",
    component: SupportHelpdesk,
  },
  {
    name: "helpdesk_mobile_view",
    path: "/helpdesk/mobile/view/:visit",
    component: SupportView,
  },
  {
    name: "helpdesk_mobile_holder_list",
    path: "/helpdesk/mobile/holder_list",
    component: SupportHelpdeskHolder,
  },
  {
    name: "helpdesk_mobile_holder",
    path: "/helpdesk/mobile/holder/:card_id",
    component: SupportHolder,
  },
  {
    name: "company_create",
    path: "/:country/companies/create",
    component: CompanyCreate,
  },
  {
    name: "company_update",
    path: "/:country/companies/update/:company",
    component: CompanyUpdate,
  },
  {
    name: "company_view",
    path: "/:country/companies/view/:company",
    component: CompanyView,
  },
  {
    name: "card_list",
    path: "/cards/list",
    component: CardIndex,
  },
  {
    name: "claim_card_list",
    path: "/claim_cards/list/:filter_at",
    component: ClaimCardIndex,
  },
  {
    name: "claim_list",
    path: "/claims/list/:filter_at",
    component: ClaimCompanyIndex,
  },
  {
    name: "order_update",
    path: "/orders/update/:order",
    component: OrderUpdate,
  },
  {
    name: "card_view",
    path: "/cards/view/:company/:card",
    component: CardView,
  },
  {
    name: "qrcode_supplier_list",
    path: "/suppliers/qrcode",
    component: SupplierQrCodeIndex,
  },
  {
    name: "schedule_list",
    path: "/suppliers/schedule_list",
    component: SupplierScheduleIndex,
  },
  {
    name: "supplier_coverage",
    path: "/suppliers/coverage",
    component: SupplierCoverageIndex,
  },
  {
    name: "supplier_act_list",
    path: "/suppliers/act_table",
    component: SupplierActIndex,
  },
  {
    name: "supplier_cover",
    path: "/suppliers/cover",
    component: SupplierCoverIndex,
  },
  {
    name: "attraction_cover",
    path: "/attractions/cover",
    component: AttractionCoverIndex,
  },
  {
    name: "by_supplier_create",
    path: "/by/suppliers/create",
    component: BySupplierCreate,
  },
  {
    name: "by_supplier_update",
    path: "/by/suppliers/update/:supplier",
    component: BySupplierUpdate,
  },
  {
    name: "default_supplier_create",
    path: "/:country/suppliers/create",
    component: AmSupplierCreate,
  },
  {
    name: "default_supplier_update",
    path: "/:country/suppliers/update/:supplier",
    component: AmSupplierUpdate,
  },
  {
    name: "supplier_view",
    path: "/suppliers/view/:supplier",
    component: SupplierView,
  },
  {
    name: "user_list",
    path: "/users/list/:user_id",
    component: UserIndex,
  },
  {
    name: "user_list",
    path: "/users/list",
    component: UserIndex,
  },
  {
    name: "mail_list",
    path: "/mail/:to",
    component: ElectronicMailIndex,
  },
  {
    name: "login",
    path: "/login",
    component: Login,
  },
  {
    name: "signup",
    path: "/sign/up",
    component: SignUp,
  },
  {
    name: "passport_forgot_password",
    path: "/passport/forgot-password",
    component: ForgotPassword,
  },
  {
    name: "passport_reset_password",
    path: "/passport/reset-password/:token",
    component: ResetPassword,
  },
  {
    name: "passport_settings",
    path: "/passport/settings",
    component: Settings,
  },
  {
    name: "holder_city_list",
    path: "/holder/city/list",
    component: CityList,
  },
  {
    name: "holder_ticket_list",
    path: "/holder/ticket/list",
    component: TicketList,
  },
  {
    name: "holder_ticket_show",
    path: "/holder/ticket/show/:ticket",
    component: TicketShow,
  },
  {
    name: "holder_ticket_create",
    path: "/holder/ticket/create",
    component: TicketCreate,
  },
  {
    name: "holder_ticket_stats",
    path: "/holder/ticket/stats",
    component: TicketStats,
  },
  /**
   * OLD routes with some updated parts
   */

  /**
   * NEW routes implementation
   */
  {
    path: "/companies",
    name: ROUTES_NAMES.companies.index,
    component: CommonLayout,
    redirect: { name: ROUTES_NAMES.companies.list },
    meta: {
      breadcrumb: "Companies",
    },
    children: [
      {
        name: ROUTES_NAMES.companies.list,
        path: "",
        component: CompaniesListView,
      },
      {
        name: ROUTES_NAMES.companies.add,
        path: "add",
        component: CompanyEditView,
        meta: {
          breadcrumb: "Add Company",
        },
      },
      {
        path: ":companyId",
        component: RouterView,
        children: [
          {
            name: ROUTES_NAMES.companies.edit,
            path: "edit",
            component: CompanyEditView,
            meta: {
              breadcrumb: "Edit Company",
            },
          },
          {
            name: ROUTES_NAMES.companies.portalUsers,
            path: "portal-users",
            component: PortalUsersView,
            meta: {
              breadcrumb: "Company Portal Users",
            },
          },
        ],
      },
    ],
  },
  {
    name: ROUTES_NAMES.countries.index,
    path: "/countries",
    component: CommonLayout,
    redirect: { name: ROUTES_NAMES.countries.list },
    meta: {
      breadcrumb: "Countries",
    },
    children: [
      {
        name: ROUTES_NAMES.countries.list,
        path: "",
        component: CountriesListView,
      },
      {
        name: ROUTES_NAMES.countries.add,
        path: "add",
        component: CountryEditView,
        meta: {
          breadcrumb: "Add Country",
        },
      },
      {
        name: ROUTES_NAMES.countries.edit,
        path: ":countryId/edit",
        component: CountryEditView,
        meta: {
          breadcrumb: "Edit Country",
        },
      },
    ],
  },
  {
    name: ROUTES_NAMES.coupons.index,
    path: "/coupons",
    component: CommonLayout,
    redirect: { name: ROUTES_NAMES.coupons.list },
    meta: {
      breadcrumb: "Coupons",
    },
    children: [
      {
        name: ROUTES_NAMES.coupons.list,
        path: "list",
        component: CouponsListView,
      },
      {
        path: ":couponsGroupId",
        component: RouterView,
        children: [
          {
            name: ROUTES_NAMES.coupons.import.index,
            path: "import",
            component: RouterView,
            redirect: { name: ROUTES_NAMES.coupons.import.view },
            meta: {
              breadcrumb: "Coupons Import",
            },
            children: [
              {
                name: ROUTES_NAMES.coupons.import.view,
                path: "",
                component: CouponsImportView,
              },
              {
                name: ROUTES_NAMES.coupons.import.history,
                path: "history",
                component: CouponsImportHistory,
                meta: {
                  breadcrumb: "Coupons Import History",
                },
              },
            ],
          },
          {
            name: ROUTES_NAMES.coupons.redeem,
            path: "redeem/:couponId",
            component: CouponRedeemView,
            meta: {
              breadcrumb: "Redeem Coupon",
            },
          },
          {
            name: ROUTES_NAMES.coupons.attractions,
            path: "attractions",
            component: CouponLinkAttractionsView,
            meta: {
              breadcrumb: "Link Attractions",
            },
          },
          {
            name: ROUTES_NAMES.coupons.search,
            path: "search",
            component: CouponsSearchView,
            meta: {
              breadcrumb: "Search Coupons",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/notifications",
    component: CommonLayout,
    children: [
      {
        path: "",
        name: ROUTES_NAMES.notifications.list,
        component: NotificationsListView,
      },
      {
        path: ":notificationId",
        name: ROUTES_NAMES.notifications.view,
        component: NotificationView,
      },
    ],
  },
  {
    path: "/orders",
    component: CommonLayout,
    children: [
      {
        path: "/orders/list",
        name: ROUTES_NAMES.orders.list,
        component: OrdersListView,
      },
      {
        path: ":orderId",
        component: RouterView,
        children: [
          {
            path: "acts",
            name: ROUTES_NAMES.orders.acts.list,
            component: OrderActsView,
          },
          {
            path: "invoices",
            name: ROUTES_NAMES.orders.invoices.list,
            component: OrderInvoicesView,
          },
        ],
      },
    ],
  },
  {
    path: "/price_models",
    component: CommonLayout,
    children: [
      {
        path: "list",
        name: ROUTES_NAMES.price_models.list,
        component: PriceModelsListView,
      },
    ],
  },
  {
    path: "/limit_rules",
    component: CommonLayout,
    children: [
      {
        path: "list",
        name: ROUTES_NAMES.limit_rules.list,
        component: LimitRulesListView,
      },
    ],
  },
  {
    path: "/subscribers",
    component: CommonLayout,
    children: [
      {
        path: ":subscriberId/audit",
        component: RouterView,
        children: [
          {
            path: "",
            name: ROUTES_NAMES.subscribers.audit,
            component: SubscriberAuditView,
          },
          {
            path: ":auditId",
            name: ROUTES_NAMES.subscribers.auditDetails,
            component: SubscriberAuditDetailsView,
          },
        ],
      },
    ],
  },
  {
    path: "/suppliers",
    component: RouterView,
    name: ROUTES_NAMES.suppliers.index,
    redirect: { name: ROUTES_NAMES.suppliers.list },
    meta: {
      breadcrumb: "Suppliers",
    },
    children: [
      {
        name: ROUTES_NAMES.suppliers.list,
        path: "list",
        component: SupplierIndex,
      },
      {
        path: ":supplierId",
        component: CommonLayout,
        children: [
          {
            path: "integrations/manage",
            name: ROUTES_NAMES.suppliers.integrations.manage,
            component: SupplierManageIntegration,
            meta: {
              breadcrumb: "Manage Attractions Connections",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/templates",
    component: CommonLayout,
    name: ROUTES_NAMES.templates.index,
    redirect: { name: ROUTES_NAMES.templates.list },
    meta: {
      breadcrumb: "Templates List",
    },
    children: [
      {
        path: "add",
        name: ROUTES_NAMES.templates.add,
        component: TemplateEditView,
        meta: {
          breadcrumb: "Add Template",
        },
      },
      {
        path: "edit/:templateId",
        name: ROUTES_NAMES.templates.edit,
        component: TemplateEditView,
        meta: {
          breadcrumb: "Edit Template",
        },
      },
      {
        path: "list",
        name: ROUTES_NAMES.templates.list,
        component: TemplatesListView,
      },
    ],
  },
  {
    path: "/transactions",
    component: CommonLayout,
    children: [
      {
        path: "list",
        name: ROUTES_NAMES.transactions.list,
        component: TransactionsListView,
      },
    ],
  },
  {
    path: "/visits",
    component: CommonLayout,
    children: [
      {
        path: "journal",
        name: ROUTES_NAMES.visits.journal,
        component: JournalOfVisitsView,
      },
      {
        path: "corrections",
        name: ROUTES_NAMES.visits.corrections,
        component: VisitCorrectionsListView,
      },
    ],
  },
];

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});
