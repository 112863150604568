<template>
  <div>
    <div>
      <nav>
        <ul class="small-layout-list">
          <li v-if="signed">
            <button @click="fireLogout">Logout</button>
          </li>
          <li>
            <router-link v-if="isSupport2manager" :to="ROUTES_NAMES.visits.journal">{{
              $t("New Log of Visits")
            }}</router-link>
          </li>
          <li v-for="(name, routeName) in routes" :key="`header_link_${routeName}`">
            <router-link :to="{ name: routeName, params: { filter_at: filterAt } }">{{
              $t("menu." + name)
            }}</router-link>
          </li>
          <li v-if="isAdmin">
            <router-link to="/audits/list">Audit</router-link>
          </li>
          <li v-if="isSaleManager">
            <router-link :to="{ name: 'claim_list', params: { filter_at: filterAt } }">Claims</router-link>
          </li>
          <li v-if="isSaleManager">
            <router-link to="/cards/list">Cards</router-link>
          </li>
          <li v-if="isSupportManager || isAccountant">
            <router-link to="/suppliers/list">Suppliers</router-link>
          </li>
          <li v-if="isSupportManager">
            <router-link to="/users/list">Users</router-link>
          </li>
          <li v-if="isAdmin">
            <router-link to="/payments/list">Payments</router-link>
          </li>
          <li v-if="isAdmin">
            <router-link :to="{ name: ROUTES_NAMES.transactions.list }">Transactions</router-link>
          </li>
          <li v-if="isAdmin">
            <router-link to="/tokens/list">Tokens</router-link>
          </li>
          <li v-if="isAdmin">
            <router-link :to="{ name: ROUTES_NAMES.templates.list }">Templates</router-link>
          </li>
          <li v-if="isAdmin || isAccountant">
            <router-link :to="{ name: ROUTES_NAMES.companies.list }">Companies</router-link>
          </li>
          <li v-if="isAdmin">
            <router-link :to="{ name: ROUTES_NAMES.countries.index }">Countries</router-link>
          </li>
          <li v-if="isAdmin">
            <router-link :to="{ name: ROUTES_NAMES.price_models.list }">Price Models</router-link>
          </li>
          <li>
            <router-link :to="{ name: ROUTES_NAMES.limit_rules.list }">Limit Rules</router-link>
          </li>
          <li v-if="showOrdersLink">
            <router-link :to="{ name: ROUTES_NAMES.orders.list, query: ordersListQueryParams }">Orders</router-link>
          </li>
          <li v-if="isSuperAdmin">
            <router-link
              :to="{ name: ROUTES_NAMES.orders.list, query: { ...ordersListQueryParams, role: 'accountant' } }"
            >
              Orders Accountant
            </router-link>
          </li>
          <li>
            <label>
              {{ $t("menu.country_title") }}
              <select :value="country" @change="setCountryEvent" class="layout_country">
                <option v-for="item in config.countries" :selected="item === country" :value="item" :key="item">
                  {{ item }}
                </option>
              </select>
            </label>
          </li>
          <li>
            <labeL>
              {{ $t("menu.language_title") }}
              <select :value="current_locale" @change="setLocale" class="layout_country">
                <option
                  v-for="item in config.supported_languages"
                  :selected="item === current_locale"
                  :value="item"
                  :key="item"
                >
                  {{ item }}
                </option>
              </select>
            </labeL>
          </li>
          <li class="build-info">
            <p>Build: {{ bitbucketBuildNumber }}</p>
          </li>
        </ul>
      </nav>
      <validation-errors :errors="errorResponse" v-if="errorResponse"></validation-errors>
      <transition name="fade">
        <slot></slot>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ValidationErrors from "../ValidationErrors";
import { formatToRFC3339 } from "../../middleware/functions";
import config from "../../config";
import { ROUTES_NAMES } from "@/router";
import { DEPLOYMENT_RELEASE } from "@/config/index";

export default {
  name: "small_app",
  data() {
    const date = new Date();
    if (date.getDate() >= 15) {
      date.setMonth(date.getMonth() + 1);
    }
    const filterAt = formatToRFC3339(date, false);

    return {
      ROUTES_NAMES,

      filterAt,
      config,
    };
  },
  computed: {
    ...mapGetters({
      routes: "auth/routes",
      country: "auth/country",
      signed: "auth/loggedin",
      current_locale: "auth/locale",
      isSaleManager: "auth/isSaleManager",
      isSupport2manager: "auth/isLocalManager",
      isAdmin: "auth/isAdmin",
      isSupportManager: "auth/isSupportManager",
      isAccountant: "auth/isAccountant",
      isSuperAdmin: "auth/isSuperAdmin",
      errorResponse: "error/getErrorResponse",
    }),
    bitbucketBuildNumber: () => DEPLOYMENT_RELEASE,
    showOrdersLink() {
      return this.isSaleManager || this.isAccountant;
    },
    ordersListQueryParams() {
      const { role, ...params } = this.$route.query;
      return params;
    },
  },
  components: {
    ValidationErrors,
  },
  methods: {
    ...mapActions({
      setLanguage: "auth/setLanguage",
      setCountry: "auth/setCountry",
      logout: "auth/logout",
    }),
    setCountryEvent(e) {
      this.setCountry(e.target.value);
      window.location.reload();
    },
    setLocale(e) {
      this.setLanguage(e.target.value);
    },
    fireLogout() {
      this.logout();
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

li {
  float: left;

  button,
  a {
    font-size: 16px;
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;

    :hover:not(.active) {
      background-color: #111;
    }

    .active {
      background-color: #4caf50;
    }
  }
}
</style>

<style lang="scss">
.small-layout {
  &-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-family: "Montserrat", sans-serif;

    label {
      color: #fff;
      margin-left: 1em;

      select {
        text-align: center;
        border: none;
        appearance: none;
      }
    }

    button {
      margin: 0;
      border: none;
    }
  }
}
/* Tables
================================== */
.hide_full_view {
  height: 120px;
  overflow: hidden;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
}
.flex-cell {
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%; // Default to full width
  padding: 0.8em 1.2em;
  overflow: hidden; // Or flex might break
  list-style: none;
  border: solid 3px white;
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin: 0;
  }
}

.layout_country {
  width: 150px;
  height: 30px;
  padding: 5px;
  color: white;
  background: black;
}
.layout_country option {
  color: white;
}
.layout_country option:first-child {
  color: white;
}

/* Table column sizing
================================== */
.flex-2cols > .flex-cell {
  width: 50%;
}
.flex-3cols > .flex-cell {
  width: 33.33%;
}
.flex-4cols > .flex-cell {
  width: 25%;
}
.flex-5cols > .flex-cell {
  width: 20%;
}
.flex-6cols > .flex-cell {
  width: 16.6%;
}
</style>
