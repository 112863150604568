<template>
  <ModalContent tag="form" @close="close">
    <template slot="header">
      <h3 v-if="isInfoMode">{{ $t("Confirmation required") }}</h3>
      <h3 v-else-if="isWarningMode">{{ $t("ATTENTION !") }}</h3>
    </template>
    <template slot="content">
      <template>
        <p :class="isWarningMode ? 'warning' : ''">
          {{ $t(errorMessage) }}
        </p>
      </template>
    </template>
    <template slot="controls">
      <Button button-type="light" auto-width @click.prevent="close">{{ $t("Cancel") }}</Button>
      <Button type="submit" auto-width @click.prevent="onSubmit">
        {{ $t("Save") }}
      </Button>
    </template>
  </ModalContent>
</template>

<script>
import { computed } from "@vue/composition-api";

import Button from "@/components/buttons/Button.vue";
import ModalContent from "@/components/modal/ModalContent.vue";
import { MODAL_MODES, MODAL_TYPES } from "@/store/modules/modal";

import store from "@/store";

export default {
  emits: ["confirmationClose"],

  components: {
    Button,
    ModalContent,
  },

  setup(_, { emit }) {
    const modal = computed(() => store.state.modal);
    const modalPayload = computed(() => modal.value.payload);
    const errorMessage = computed(() => modalPayload.value.visitModalPayload.errorMessage);
    const isInfoMode = computed(() => modal.value.mode === MODAL_MODES[MODAL_TYPES.confirmVisit].info);
    const isWarningMode = computed(() => modal.value.mode === MODAL_MODES[MODAL_TYPES.confirmVisit].warning);

    async function onSubmit() {
      const payload = modalPayload.value.visitModalPayload;
      await modalPayload.value.onAccept(payload);
      modalPayload.value.onSubmitConfirmation();

      close();
    }

    function close() {
      emit("close");
    }

    return {
      isInfoMode,
      isWarningMode,
      errorMessage,

      onSubmit,
      close,
    };
  },
};
</script>

<style scoped>
.warning {
  color: red;
}
</style>
