<template>
  <div class="search-input" :class="elementClass">
    <label :for="inputId">
      <p v-if="props.label" class="search-input_label">
        {{ props.label }}
        <Tooltip v-if="props.required" text="Required field" color="#e3001b" />
      </p>
    </label>
    <div :class="searchInputContainerClass">
      <input
        :id="inputId"
        v-model="searchValue"
        v-click-outside="onClickOutside"
        type="text"
        class="search-input_input"
        placeholder="Type to search..."
        :disabled="props.disabled"
        @focus="onFocus"
        ref="input"
      />
      <span class="search-input_status">
        <SpinnerRadial v-if="processingState" />
        <Icon v-else-if="showDropdown" icon="arrow-up" />
        <Icon v-else icon="arrow-down" />
      </span>
    </div>
    <div v-if="showError" class="search-input_error" :class="errorClass" data-error-type="message">
      <template v-if="errorMsg === VALIDATION_ERRORS.requiredField">{{ $t("Select value") }}</template>
      <template v-else>{{ errorMsg || "Ошибка поля" }}</template>
    </div>
    <ul :class="{ 'search-input_dropdown': true, static: props.staticDropdown }">
      <li v-for="item in state.items" :key="`search-input-${_uid}-${item[props.modelField]}`">
        <a
          href="#"
          :title="getItemText(item)"
          @click.prevent="onSelect(item)"
          :style="{ color: validateField(item) ? 'red' : undefined }"
        >
          {{ getItemText(item) }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, reactive, watch, onBeforeMount, nextTick, ref } from "@vue/composition-api";
import debounce from "lodash/debounce";
import isFunction from "lodash/isFunction";

import Icon from "@/components/icons/Icon.vue";
import SpinnerRadial from "@/components/loaders/SpinnerRadial.vue";
import Tooltip from "@/components/tooltips/Tooltip.vue";

import request from "@/helpers/request";
import { VALIDATION_ERRORS } from "@/helpers/validators";

const BASE_CLASS = "search-input";
const ERROR_CLASS = `${BASE_CLASS}_error`;
const DEFAULT_SEARCH_TIMEOUT = 500;

export default {
  emits: ["update:modelValue", "select"],

  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    inputMode: {
      type: Boolean,
      default: false,
    },
    itemTextField: {
      type: [String, Function],
      required: true,
    },
    itemFieldValidator: {
      type: Function,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    modelField: {
      type: String,
      default: "id",
    },
    offlineItems: {
      type: Array,
      required: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    searchParams: {
      type: Object,
      default: () => ({}),
    },
    searchUrl: {
      type: Function,
      required: false,
    },
    searchField: {
      type: String,
      required: false,
    },
    staticDropdown: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: DEFAULT_SEARCH_TIMEOUT,
    },
    value: {
      type: [String, Number],
      required: false,
    },
    modelValue: {
      type: [String, Number, null],
      required: false,
    },
  },

  components: {
    Icon,
    SpinnerRadial,
    Tooltip,
  },

  setup(props, { emit }) {
    if (!props.offlineItems && !props.searchUrl) {
      throw "You need to pass (offlineItems) or (searchUrl with searchField) prop!";
    }

    const input = ref(null);

    // Replace it with useId from Nuxt in the next big release
    const inputId = `search-input-id-${Date.now()}`;

    const searchValue = computed({
      get() {
        const textByValue = props.offlineItems?.find((item) => item.value === props.modelValue)?.text;

        return textByValue || props.modelValue;
      },
      set(newVal) {
        state.isChanged = true;

        if (props.inputMode) {
          emit("update:modelValue", newVal);
        }

        if (newVal === "") {
          if (props.offlineItems && props.offlineItems.length > 0) {
            state.items = props.offlineItems;
          } else {
            state.items = [];
          }

          emit("update:modelValue", null);
        } else {
          if (props.offlineItems) {
            searchOffline(newVal);
          } else {
            searchOnline(newVal);
          }
        }
        emit("update:modelValue", newVal);
      },
    });

    const state = reactive({
      items: [],

      processing: false,
      error: null,
      focused: false,
      preventSearchingOnce: false,
      isChanged: false,
    });

    const processingState = computed(() => props.processing || state.processing);
    const errorMsg = computed(() => (state.isChanged ? state.error : props.error));
    const showError = computed(() => (props.error && !state.isChanged) || state.error);
    const showDropdown = computed(() => state.focused && state.items.length > 0);
    const elementClass = computed(() => ({
      [`${BASE_CLASS}__disabled`]: props.disabled,
      [`${BASE_CLASS}__focused`]: state.focused,
      [`${BASE_CLASS}__labeled`]: !!props.label,
      [`${BASE_CLASS}__opened`]: showDropdown.value,
      ["_error"]: (props.error && !state.isChanged) || state.error,
    }));
    const errorClass = computed(() => ({
      [`${ERROR_CLASS}__visible`]: showError.value,
    }));
    const searchInputContainerClass = computed(() => ({
      ["search-input_container"]: true,
    }));

    function onFocus() {
      state.focused = true;
    }

    function onClickOutside() {
      state.focused = false;
      blur();
    }

    function reset() {
      searchValue.value = "";
    }

    function onSelect(item) {
      const value = item[props.modelField];

      state.items = [];
      state.preventSearchingOnce = true;
      searchValue.value = value;
      emit("select", value);
    }

    function getItemText(item) {
      let text;

      if (isFunction(props.itemTextField)) {
        text = props.itemTextField(item);
      } else {
        text = item[props.itemTextField];
      }

      return text;
    }

    function validateField(item) {
      if (isFunction(props.itemFieldValidator)) {
        return props.itemFieldValidator(item);
      }

      return false;
    }

    function searchOffline(searchValue) {
      if (searchValue === "") {
        state.items = props.offlineItems;
        return;
      }

      const filteredList = props.offlineItems.filter((item) => {
        const isMatched = Object.values(item)
          .map((val) => (val?.toLowerCase ? val.toLowerCase() : val))
          .join(" ")
          .includes(searchValue?.toLowerCase ? searchValue?.toLowerCase() : searchValue);
        return isMatched;
      });

      state.items = filteredList;
    }

    const searchOnline = debounce(async (searchValue) => {
      if (state.preventSearchingOnce) {
        state.preventSearchingOnce = false;
        return;
      }

      const url = props.searchUrl({
        [props.searchField]: searchValue,
        ...props.searchParams,
      });

      state.error = null;
      state.processing = true;

      try {
        const result = await request(url);

        if (searchValue.length > 0) {
          state.items = result;
        }
      } catch (error) {
        state.error = error;
      }

      state.processing = false;
    }, props.timeout);

    function blur() {
      nextTick(() => {
        if (input.value instanceof HTMLElement) {
          input.value.blur();
        }
      });
    }

    watch(
      () => props.error,
      () => {
        state.isChanged = false;
      }
    );
    watch(
      () => props.offlineItems,
      (newVal) => (state.items = newVal)
    );

    onBeforeMount(() => {
      if (props.offlineItems) {
        state.items = props.offlineItems;
      }
    });

    return {
      VALIDATION_ERRORS,

      props,
      state,

      searchValue,

      input,
      inputId,
      elementClass,
      errorClass,
      searchInputContainerClass,
      errorMsg,
      showError,
      showDropdown,
      processingState,
      reset,

      onFocus,
      onSelect,
      getItemText,
      validateField,
      onClickOutside,
    };
  },
};
</script>

<style lang="scss" scoped>
$input_height: 36px;
$padding-bottom: 24px;

.search-input {
  $search_input: #{&};
  $search_input__disabled: #{&}__disabled;
  $search_input__focused: #{&}__focused;
  $search_input__labeled: #{&}__labeled;
  $search_input__opened: #{&}__opened;

  position: relative;
  padding-bottom: $padding-bottom;

  &_label {
    display: inline-flex;
    gap: 6px;
    font-size: 18px;
    font-weight: 500;
    color: #444545;
    margin-bottom: 6px;
  }

  &_container {
    display: block;
    width: 100%;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    box-sizing: border-box;

    #{$search_input__focused} & {
      border-color: #000;
    }

    #{$search_input__opened} & {
      border-radius: 8px 8px 0 0;
    }
  }

  &._error {
    .search-input_container {
      border: 1px solid #e3001b;
    }
  }

  &_input {
    background: #fff;
    border-radius: 8px;
    padding: 0 30px 0 12px;
    width: 100%;
    height: $input_height;

    #{$search_input__disabled} & {
      background: #e7e7e7;
    }

    &:hover {
      background-color: #fafafa;

      #{$search_input__disabled} & {
        background-color: #e7e7e7;
      }
    }
  }

  &_status {
    position: absolute;
    top: 0;
    right: 0;
    width: $input_height;
    height: $input_height;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_dropdown {
    width: 100%;
    position: absolute;
    top: $input_height;
    left: 0;
    list-style-type: none;
    z-index: 1;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 0px 0px 8px 8px;
    max-height: 230px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: none;

    #{$search_input__opened} & {
      display: block;
    }

    #{$search_input__focused} & {
      border-color: #000;
    }

    #{$search_input__labeled} & {
      top: calc(100% - $padding-bottom);
    }

    li {
      &:hover {
        background-color: #fafafa;
      }

      & + li {
        border-top: 1px solid rgba(0, 0, 0, 0.3);
      }

      a {
        padding: 7px;
        display: block;
        font-weight: 400;
        font-size: 13px;
        color: #000;
        line-height: 16px;
      }
    }

    &.static {
      position: static;
    }
  }

  &_error {
    position: absolute;
    bottom: 0;
    left: 0;

    font-size: 13px;
    color: #e3001b;
    line-height: $padding-bottom;
    display: inline-block;
    max-height: $padding-bottom;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    visibility: hidden;

    &__visible {
      visibility: visible;
    }
  }
}
</style>
