<template>
  <ModalContent tag="form" @close="close">
    <template slot="header">
      <h3 v-if="isEditDefaultMode">Edit Default Limit Rule</h3>
      <h3 v-else-if="isEditCustomMode">Edit Custom Limit Rule</h3>
      <h3 v-else-if="isAddMode">Add Custom Limit Rule</h3>
    </template>
    <template slot="content">
      <ErrorBanner :message="errors.errorMessage" />
      <SearchInput
        v-if="!isEditDefaultMode"
        v-model="state.selectedCompany"
        :label="$t('Company')"
        :item-text-field="({ text }) => text"
        model-field="value"
        :offline-items="companiesOptions"
        :error="errors.selectedCompany"
        input-mode
      />
      <BaseInput
        :error="errors.premiumVisitLimits"
        label="Premium+ Limit amount (Default 4)"
        type="number"
        v-model="state.premiumVisitLimits"
        validator="number"
      />
      <BaseInput
        :error="errors.vipVisitLimits"
        label="Vip+ Limit amount (Default 4)"
        type="number"
        v-model="state.vipVisitLimits"
        validator="number"
      />
    </template>
    <template slot="controls">
      <Button button-type="light" auto-width @click.prevent="close">{{ $t("Cancel") }}</Button>
      <Button type="submit" :processing="processing" auto-width @click.prevent="onSubmit">
        {{ $t("Save") }}
      </Button>
    </template>
  </ModalContent>
</template>

<script>
import { computed, reactive, onBeforeMount } from "@vue/composition-api";

import BaseInput from "@/components/inputs/BaseInput.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import ModalContent from "@/components/modal/ModalContent.vue";
import SearchInput from "@/components/inputs/SearchInput.vue";

import { MODAL_MODES, MODAL_TYPES } from "@/store/modules/modal";
import { VALIDATION_ERRORS } from "@/helpers/validators";
import store from "@/store";
import URLS from "@/config/urls";
import useRequest from "@/composables/network/useRequest";
import validateRequiredFields from "@/helpers/validators/inputs/validateRequiredFields";

export default {
  emits: ["close"],

  components: {
    BaseInput,
    Button,
    ErrorBanner,
    ModalContent,
    SearchInput,
  },

  setup(_, { emit }) {
    const { errors, fetch, processing, setErrors } = useRequest({
      errorsFormat: "flat",
    });

    const state = reactive({
      companiesList: [],
      premiumVisitLimits: null,
      vipVisitLimits: null,
      limitId: null,
      selectedCompany: null,
    });

    const modalStore = computed(() => store.state.modal);
    const modalPayload = computed(() => modalStore.value.payload);

    const isAddMode = computed(() => modalStore.value.mode === MODAL_MODES[MODAL_TYPES.limitRuleModal].add);
    const isEditCustomMode = computed(() => modalStore.value.mode === MODAL_MODES[MODAL_TYPES.limitRuleModal].edit);
    const isEditDefaultMode = computed(
      () => modalStore.value.mode === MODAL_MODES[MODAL_TYPES.limitRuleModal].editDefault
    );

    const requiredFields = computed(() => [
      "vipVisitLimits",
      "premiumVisitLimits",
      ...(isAddMode.value || isEditCustomMode.value ? ["selectedCompany"] : []),
    ]);

    const companiesOptions = computed(() => {
      const noCompanyOption = { text: "No company", value: null };
      const companiesOptions = state.companiesList.map(({ id, name }) => ({ text: name, value: id }));
      companiesOptions.sort((a, b) => a.text?.toLowerCase().localeCompare(b.text?.toLowerCase()));
      return [noCompanyOption, ...companiesOptions];
    });

    function prepareData() {
      return {
        company_id: isEditDefaultMode.value ? null : state.selectedCompany,
        limits: {
          premium: {
            visit_limits: state.premiumVisitLimits,
          },
          vip: {
            visit_limits: state.vipVisitLimits,
          },
        },
      };
    }

    async function onSubmit() {
      if (processing.value) {
        return;
      }

      const errors = validateForm();

      if (errors) {
        setErrors(errors);
        return;
      }

      const url = !isAddMode.value ? URLS.limits.single(state.limitId) : URLS.limits.index();
      const method = !isAddMode.value ? "PUT" : "POST";
      const requestData = prepareData();
      const { data, error } = await fetch({
        url,
        method,
        data: requestData,
      });

      if (!error) {
        if (modalPayload.value && modalPayload.value.onAccept) {
          await modalPayload.value.onAccept(data);
        }

        close();
      }
    }

    function validateForm() {
      const visitLimitsFields = ["vipVisitLimits", "premiumVisitLimits"];
      const errors = validateRequiredFields(state, requiredFields.value) || {};
      const requiredFieldsSet = new Set(requiredFields.value);

      const hasNegativeValue = visitLimitsFields.some((field) => requiredFieldsSet.has(field) && state[field] < 0);

      if (hasNegativeValue) {
        errors.errorMessage = VALIDATION_ERRORS.negativeNumber;
      }

      return Object.keys(errors).length > 0 ? errors : null;
    }

    function close() {
      emit("close");
    }

    onBeforeMount(() => {
      const payload = modalPayload.value;
      state.companiesList = payload.companies;

      if (isEditCustomMode.value || isEditDefaultMode.value) {
        const { limits, limitId } = payload;
        const {
          premium: { visitLimits: premiumVisitLimits },
          vip: { visitLimits: vipVisitLimits },
        } = limits;

        state.limitId = limitId;
        state.premiumVisitLimits = premiumVisitLimits;
        state.vipVisitLimits = vipVisitLimits;
      }

      if (isEditCustomMode.value) {
        state.selectedCompany = payload.selectedCompany;
      }
    });

    return {
      errors,
      processing,

      state,

      companiesOptions,
      isEditCustomMode,
      isEditDefaultMode,
      isAddMode,

      onSubmit,
      close,
    };
  },
};
</script>

<style lang="scss" scoped></style>
