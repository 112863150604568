<template>
  <ModalContent tag="form" @close="close">
    <template slot="header">
      <h3 v-if="isEditMode">Edit price model</h3>
      <h3 v-else>Add price model</h3>
    </template>
    <template slot="content">
      <ErrorBanner :message="errors.errorMessage" />
      <NewSelectInput
        v-model="priceModel.seller"
        :placeholder="$t('Select seller')"
        :error="errors.seller"
        :label="$t('Seller')"
        :options="sellersOptions"
        @select="onSellerChange"
      />
      <SearchInput
        v-model="priceModel.company"
        :label="$t('Company')"
        :item-text-field="({ text }) => text"
        model-field="value"
        :offline-items="companiesOptions"
        :error="errors.company"
        input-mode
      />
      <NewSelectInput
        v-model="priceModel.currency"
        :placeholder="$t('Select currency')"
        :error="errors.currency"
        :label="$t('Currency')"
        :options="availableCurrenciesOptions"
        :max-options-visible="3"
      />
      <NewSelectInput
        v-model="priceModel.subscriptionModel"
        :placeholder="$t('Subscription Model')"
        :error="errors.subscriptionModel"
        :label="$t('Subscription Model')"
        :options="subscriptionModelOptions"
        @select="onSubscriptionModelChange"
      />
      <h4>{{ $t("Pricing policy") }}:</h4>
      <div v-if="priceModel.pricingPolicies.length > 0" class="headers">
        <div class="headers_item">{{ $t("Condition") }}:</div>
        <div class="headers_item wide">{{ $t("Subscription type") }}:</div>
        <div class="headers_item">{{ $t("Cost per item") }}:</div>
      </div>
      <div
        v-for="(policy, index) in priceModel.pricingPolicies"
        class="inputs-group"
        :key="`pricing-policy-${policy._id}`"
      >
        <BaseInput
          :errorBorderColor="checkConditionErrors(index)"
          v-model="policy.condition"
          class="inputs-group_item"
        />
        <NewSelectInput
          :class="{ errorBorder: checkSubscriptionTypeErrors(index) }"
          v-model="policy.subscriptionType"
          :options="countrySubscriptionTypeOptions"
          class="inputs-group_item__wide"
        />
        <BaseInput :errorBorderColor="checkCostErrors(index)" v-model="policy.cost" class="inputs-group_item" />
        <a href="#" class="action_button action_button__delete" @click.prevent="deletePricingPolicy(policy._id, index)">
          <Icon icon="delete" class="action_button_icon" height="28px" width="24px" />
        </a>
      </div>
      <AddInstanceButton :class="{ error: isPricingPolicyInProgress }" @click="addNewPricingPolicy">{{
        $t("Add pricing policy")
      }}</AddInstanceButton>
    </template>
    <template slot="controls">
      <Button button-type="light" auto-width @click.prevent="close">{{ $t("Cancel") }}</Button>
      <Button
        type="submit"
        :processing="processing"
        auto-width
        @click.prevent="onSubmit"
        :disabled="!isSubmitButtonEnabled"
      >
        {{ $t("Save") }}
      </Button>
    </template>
  </ModalContent>
</template>

<script>
import { computed, ref, reactive, onBeforeMount, set, del } from "@vue/composition-api";
import cloneDeep from "lodash/cloneDeep";

import AddInstanceButton from "@/components/buttons/AddInstanceButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import Button from "@/components/buttons/Button.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import Icon from "@/components/icons/Icon.vue";
import ModalContent from "@/components/modal/ModalContent.vue";
import SearchInput from "@/components/inputs/SearchInput.vue";

import { MODAL_MODES, MODAL_TYPES } from "@/store/modules/modal";
import { useRoute } from "@/helpers/router/routeCompositionAPI";
import store from "@/store";
import URLS from "@/config/urls";
import useIsObjectChanged from "@/composables/useIsObjectChanged";
import useRequest from "@/composables/network/useRequest";
import validateRequiredFields from "@/helpers/validators/inputs/validateRequiredFields";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";
import { SUBSCRIPTION_MODEL } from "@/store/modules/config";

const REQUIRED_FIELDS = ["currency", "pricingPolicies", "seller", "company"];
const PRICING_POLICY_REQUIRED_FIELDS = ["_id", "condition", "cost", "subscriptionType"];

export default {
  emits: ["close"],

  components: {
    AddInstanceButton,
    BaseInput,
    Button,
    ErrorBanner,
    Icon,
    ModalContent,
    SearchInput,
    NewSelectInput,
  },

  setup(_, { emit }) {
    const route = useRoute();

    const { errors, fetch, processing, setErrors } = useRequest({
      errorsFormat: "flat",
    });

    const priceModelInitialState = ref({});

    const state = reactive({
      processingCompanies: true,
      companiesList: [],
    });

    const priceModel = reactive({
      id: null,
      currency: null,
      pricingPolicies: [],
      seller: null,
      company: null,
      subscriptionModel: SUBSCRIPTION_MODEL.DEFAULT_LIMITED,
    });

    const pricingPolicyErrorsMap = reactive(
      {
        conditionErrors: {},
        subscriptionTypeErrors: {},
        costErrors: {},
      },
      { deep: true }
    );

    // check if pricingPolicyErrorsMap contains at least one active error
    const pricingPolicyErrors = computed(() => [
      ...Object.values(pricingPolicyErrorsMap.conditionErrors),
      ...Object.values(pricingPolicyErrorsMap.subscriptionTypeErrors),
      ...Object.values(pricingPolicyErrorsMap.costErrors),
    ]);

    const { isObjectChanged } = useIsObjectChanged(priceModelInitialState, priceModel);

    const configStore = computed(() => store.state.config);
    const modalStore = computed(() => store.state.modal);
    const modalPayload = computed(() => modalStore.value.payload);

    const countryId = computed(() => route.value.params.countryId || store.state.auth.country);
    const country = computed(() => countryId.value.toLowerCase());

    const subscriptionModelOptions = computed(() => configStore.value.subscriptionModel);

    const mapSubscriptionModelToLevels = ["archivedLevels", "levels"];

    const countrySubscriptionLevels = computed(() => {
      const levels = mapSubscriptionModelToLevels[priceModel.subscriptionModel];
      return configStore.value.portal[levels][country.value];
    });

    const countrySubscriptionTypeOptions = computed(() =>
      countrySubscriptionLevels.value.map((level) => ({ value: level, text: level }))
    );

    const isEditMode = computed(() => priceModel.id);
    const isCopyMode = computed(() => modalStore.value.mode === MODAL_MODES[MODAL_TYPES.priceModel].copy);
    const isFullMode = computed(() => {
      const isFullMode = modalStore.value.mode === MODAL_MODES[MODAL_TYPES.priceModel].full;
      return isCopyMode.value || isFullMode;
    });

    const isPricingPolicyInProgress = ref(false);
    const isSubmitButtonEnabled = computed(() => isObjectChanged || isCopyMode.value);

    const availableCurrenciesOptions = computed(() => {
      const { company, seller } = priceModel;

      let availableCurrencies;

      if (modalPayload.value.availableCurrencies) {
        if (seller) {
          availableCurrencies = modalPayload.value.availableCurrencies?.sellers[seller];
        } else {
          availableCurrencies = modalPayload.value.availableCurrencies?.country;
        }
      }

      if (company || !availableCurrencies) {
        if (!configStore.value.portal) {
          throw new Error("Portal config is not loaded!");
        }

        availableCurrencies = configStore.value.portal.availableCurrencies;
      }

      return availableCurrencies.map((value) => ({ text: value, value }));
    });

    const companiesOptions = computed(() => {
      const noCompanyOption = { text: "No company", value: null };
      const companiesOptions = state.companiesList.map(({ id, name }) => ({ text: name, value: id }));
      companiesOptions.sort((a, b) => a.text?.toLowerCase().localeCompare(b.text?.toLowerCase()));
      return [noCompanyOption, ...companiesOptions];
    });

    const sellersOptions = computed(() => {
      const countrySellersList = configStore.value.country.sellers;
      const countrySellersOptions = countrySellersList.map(({ code, name }) => ({ text: name, value: code }));
      const noSellerOption = { text: "No Seller", value: null };
      return [noSellerOption, ...countrySellersOptions];
    });

    function checkConditionErrors(index) {
      return pricingPolicyErrorsMap.conditionErrors[index] || false;
    }

    function checkSubscriptionTypeErrors(index) {
      return pricingPolicyErrorsMap.subscriptionTypeErrors[index] || false;
    }

    function checkCostErrors(index) {
      return pricingPolicyErrorsMap.costErrors[index] || false;
    }

    function addNewPricingPolicy() {
      const lastPolicyId = priceModel.pricingPolicies.at(-1)?._id || 0;
      priceModel.pricingPolicies.push({
        _id: lastPolicyId + 1,
        condition: "",
        cost: "",
        subscriptionType: "",
      });

      isPricingPolicyInProgress.value = false;
    }

    function deletePricingPolicy(policyId, index) {
      priceModel.pricingPolicies = priceModel.pricingPolicies.filter(({ _id }) => _id !== policyId);
      del(pricingPolicyErrorsMap.conditionErrors, index);
      del(pricingPolicyErrorsMap.subscriptionTypeErrors, index);
      del(pricingPolicyErrorsMap.costErrors, index);
    }

    function prepareData() {
      const pricingPolicy = priceModel.pricingPolicies
        .flatMap(({ condition, cost, subscriptionType }) => ["", condition, subscriptionType, cost, ""].join("|"))
        .join("\n");

      return {
        company_id: priceModel.company,
        currency: priceModel.currency,
        pricing_policy: pricingPolicy,
        seller_code: priceModel.seller,
        subscription_model: priceModel.subscriptionModel,
      };
    }

    async function onSubmit() {
      if (processing.value) {
        return;
      }

      const validationFormErrors = validateForm();

      if (validationFormErrors) {
        setErrors(validationFormErrors);
        return;
      }

      if (pricingPolicyErrors.value.includes(true)) {
        // Do not make a request if at least one field from Pricing Policy is empty
        return;
      }

      const url = isEditMode.value
        ? URLS.countries.priceModels.single(countryId.value, priceModel.id)
        : URLS.countries.priceModels.index(countryId.value);
      const method = isEditMode.value ? "PUT" : "POST";
      const requestData = prepareData();

      const { data, error } = await fetch({
        url,
        method,
        data: requestData,
      });

      if (!error) {
        if (modalPayload.value && modalPayload.value.onAccept) {
          await modalPayload.value.onAccept(data);
        }

        close();
      }
    }

    function validateForm() {
      const validationPriceModelErrors = validateRequiredFields(priceModel, REQUIRED_FIELDS) || {};

      REQUIRED_FIELDS.forEach((item) => {
        if (item === "pricingPolicies") {
          if (!priceModel[item].length) {
            validationPriceModelErrors[item] = item;
            isPricingPolicyInProgress.value = true;
          } else {
            priceModel[item].forEach((pricingPolicyItem, index) => {
              const validationPricingPolicyErrors = validateRequiredFields(
                pricingPolicyItem,
                PRICING_POLICY_REQUIRED_FIELDS
              );
              set(pricingPolicyErrorsMap.conditionErrors, index, Boolean(validationPricingPolicyErrors?.condition));
              set(
                pricingPolicyErrorsMap.subscriptionTypeErrors,
                index,
                Boolean(validationPricingPolicyErrors?.subscriptionType)
              );
              set(pricingPolicyErrorsMap.costErrors, index, Boolean(validationPricingPolicyErrors?.cost));
            });
          }
        }
      });

      return Object.keys(validationPriceModelErrors).length ? validationPriceModelErrors : null;
    }

    async function getCompanies() {
      state.processingCompanies = true;

      if (!state.companiesList || state.companiesList.length === 0) {
        const { error, data } = await fetch(URLS.companies.index);

        if (!error) {
          state.companiesList = data;
        }
      }

      state.processingCompanies = false;
    }

    function onSellerChange() {
      if (!isCopyMode.value) {
        priceModel.currency = null;
      }
    }

    // Clear subscriptionType field in pricingPolicy after changing of subscriptionModel
    function onSubscriptionModelChange() {
      priceModel.pricingPolicies.forEach((policy) => {
        policy.subscriptionType = "";
      });
    }

    function close() {
      emit("close");
    }

    onBeforeMount(() => {
      const { companies, priceModel: payloadPriceModel } = modalPayload.value;

      state.companiesList = companies || [];

      if (payloadPriceModel) {
        const { company, currency, id, pricingPolicy, seller, subscriptionModel } = payloadPriceModel;
        const pricingPolicies = pricingPolicy.split("\n").map((policy, index) => {
          const [condition, subscriptionType, cost] = policy.split("|").slice(1, -1);
          return {
            _id: index + 1,
            condition,
            cost,
            subscriptionType,
          };
        });

        priceModel.company = company?.id || null;
        priceModel.currency = currency;
        priceModel.id = id;
        priceModel.pricingPolicies = pricingPolicies;
        priceModel.seller = seller?.code || null;
        priceModel.subscriptionModel = subscriptionModel;
      }

      priceModelInitialState.value = cloneDeep(priceModel);

      getCompanies();
    });

    return {
      errors,
      processing,

      priceModel,
      state,

      availableCurrenciesOptions,
      companiesOptions,
      sellersOptions,
      subscriptionModelOptions,
      isEditMode,
      isFullMode,
      isSubmitButtonEnabled,
      isPricingPolicyInProgress,
      countrySubscriptionTypeOptions,

      addNewPricingPolicy,
      deletePricingPolicy,
      onSubscriptionModelChange,
      checkConditionErrors,
      checkSubscriptionTypeErrors,
      checkCostErrors,
      onSellerChange,
      onSubmit,
      close,
    };
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 22px;
  color: #444545;
}

.headers {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 15px;
  color: #444545;
  &_item {
    width: 107px;

    &.wide {
      margin-right: 51px;
    }
  }
}

.error {
  color: #e3001b;
}

.inputs-group {
  display: flex;
  align-items: center;
  gap: 20px;

  &_item {
    width: 107px;

    &__wide {
      width: 158px;
    }
  }

  .errorBorder {
    .select_body {
      border-color: #e3001b;
    }
  }

  &_delete-button {
    color: #444545;
  }

  .action_button {
    display: inline-block;
    margin-bottom: 24px;

    &__delete {
      &:hover {
        .action_button_icon {
          fill: #d63230;
        }
      }
    }

    .action_button_icon {
      fill: #9c9c9c;
    }
  }
}
</style>

<style lang="scss">
.inputs-group {
  .errorBorder {
    .select_body {
      border-color: #e3001b;
    }
  }
}
</style>
