<template>
  <div class="container">
    <div class="row-header">
      <h1>{{ $t("Journal of Visits") }}</h1>
      <div class="row-header_controls">
        <a ref="download" style="display: none"></a>
        <Button button-type="light" :processing="exportExcelProcessing" @click="exportExcel">{{
          $t("Export EXCEL")
        }}</Button>
        <Button @click="openAddVisitModal">{{ $t("Add visit") }}</Button>
      </div>
    </div>
    <ErrorBanner :message="errorMessage" />
    <div class="content">
      <div class="controls">
        <NewSelectInput
          class="select_input"
          :options="state.filters"
          :placeholder="$t('More')"
          permanent-placeholder
          multiple-choices
          @select="onActiveFiltersChange"
        >
          <template #option="slotProps">
            <span :title="$t(slotProps.option.text)">{{ $t(slotProps.option.text) }}</span>
          </template>
        </NewSelectInput>
        <Button button-type="light" class="controls_button" @click="fetchVisits">
          {{ $t("Search") }}
        </Button>
        <Button button-type="light" class="controls_button" @click="resetFilters">
          {{ $t("Reset filters") }}
        </Button>
      </div>
      <div class="filters">
        <BaseInput v-model="state.pageNum" class="search_input" label="Page" :placeholder="$t('Page number')" />
        <BaseInput
          v-if="state.filters.id.value"
          v-model="state.requestQueryParams.id"
          class="search_input"
          label="Visit ID"
          :placeholder="$t('ID')"
        />
        <DatePickerInput
          v-if="state.filters.date.value"
          v-model="state.requestQueryParams.date"
          :label="$t('From(Date)')"
          class="datepicker"
          :placeholder="$t('yyyy-mm-dd')"
          :clearable="false"
        />
        <DatePickerInput
          v-if="state.filters.date_finish.value"
          v-model="state.requestQueryParams.date_finish"
          :label="$t('To(Date)')"
          class="datepicker"
          :placeholder="$t('yyyy-mm-dd')"
          :clearable="false"
          :disabled-date="disabledDatesBeforeDateFrom"
        />
        <BaseInput
          v-if="state.filters.status.value"
          v-model="state.requestQueryParams.status"
          class="search_input"
          label="Status"
          :placeholder="$t('Status')"
        />
        <BaseInput
          v-if="state.filters.reason.value"
          v-model="state.requestQueryParams.reason"
          class="search_input"
          label="Reason"
          :placeholder="$t('Reason')"
        />
        <BaseInput
          v-if="state.filters.card_search.value"
          v-model="state.requestQueryParams.card_search"
          class="search_input"
          label="Holder"
          :placeholder="$t('Holder name')"
        />
        <BaseInput
          v-if="state.filters.client_search.value"
          v-model="state.requestQueryParams.client_search"
          class="search_input"
          label="Company"
          :placeholder="$t('Company name')"
        />
        <BaseInput
          v-if="state.filters.supplier_search.value"
          v-model="state.requestQueryParams.supplier_search"
          class="search_input"
          label="Supplier"
          :placeholder="$t('Supplier name')"
        />
        <NewSelectInput
          v-if="state.filters.level.value"
          v-model="state.requestQueryParams.level"
          class="select_input"
          :label="$t('Subscription')"
          :options="subscriptionTypeOptions"
          @select="onSubscriptionTypeChange"
        />
        <NewSelectInput
          v-if="state.filters.city.value"
          v-model="state.requestQueryParams.city"
          class="select_input"
          :label="$t('Location')"
          :options="locationsOptions"
          @select="onlocationChange"
        />
      </div>

      <SimpleTable
        :actions="tableData.actions"
        :cells="tableData.cells"
        :headers="tableData.headers"
        :translatableColumns="TRANSLATABLE_TABLE_CELLS"
        :items="sortedVisitsList"
        :primary-key="tableData.primaryKey"
        :processing="processing"
        show-actions-button
        :sortable-headers="SORTABLE_TABLE_HEADERS"
        :clickable-cells="tableData.clickableCells"
        @open-additional-info-modal="openAdditionalInfoModal"
        @open-edit-visit-modal="openEditVisitModal"
        @click:clickable="onClickableCellClick"
        @sort-header="onSortVisitsByHeader"
        :sortable-state="state.sortingState"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from "@vue/composition-api";

import router from "@/router";
import store from "@/store";

import Button from "@/components/buttons/Button.vue";
import DatePickerInput from "@/components/inputs/DatePickerInput.vue";
import ErrorBanner from "@/components/banners/ErrorBanner.vue";
import NewSelectInput from "@/components/inputs/NewSelectInput.vue";
import SimpleTable from "@/components/tables/simple/SimpleTable.vue";
import SpinnerBrand from "@/components/loaders/SpinnerBrand.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";

import { USER_ROLES } from "@/store/modules/auth";
import formatCurrency from "@/helpers/formatters/formatCurrency";
import { CONFIG_ACTION_FETCH_CONFIGS } from "@/store/modules/config";
import { MODAL_ACTION_OPEN, MODAL_TYPES } from "@/store/modules/modal";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";
import removeAttrs from "@/helpers/removeAttrs";

const TABLE_ACTIONS = [
  {
    title: "Additionally",
    key: "open-additional-info-modal",
    icon: {
      type: "additional",
      color: "#346AED",
    },
  },
  {
    title: "Edit",
    key: "open-edit-visit-modal",
    icon: {
      type: "edit",
      color: "#79C99E",
    },
  },
];

const visitIdIndex = 0;
const holderIdIndex = 1;
const companyIdIndex = 2;
const registrationDateIndex = 3;
const visitStatusIndex = 4;
const companyNameIndex = 5;
const holderNameIndex = 6;
const supplierNameIndex = 7;
const supplierAttractionActivitiessList = 8;
const priceOfSubscriptionIndex = "9";
const priceOfEntranceIndex = 10;
const visitStatusReasonIndex = 11;
const subscriptionLevelIndex = 12;
const locationNameIndex = 13;
const supplierCoordinatesIndex = 14;
const isGeoMockedIndex = 15;
const visitAttractionIndex = 16; // Does not exist yet ==> undefined

const TABLE_CLICKABLE_CELLS = [
  visitIdIndex,
  holderNameIndex,
  companyNameIndex,
  supplierCoordinatesIndex,
  "Show on the map",
  "Show on the map <br><b>× fake</b>",
];

const SORTABLE_TABLE_HEADERS = ["Registered"];
const TRANSLATABLE_TABLE_CELLS = ["Doesn't exist yet", "Show on the map", "Show on the map <br><b>× fake</b>"];

const TABLE_HEADERS_SALES_MANAGER = [
  "Visit ID",
  "Registered",
  "Status",
  "Reason",
  "Holder",
  "Company",
  "Supplier",
  "Coordinates",
  "Subscription",
];

const TABLE_HEADERS_SUPPORT_2_MANAGER = [
  "Visit ID",
  "Registered",
  "Status",
  "Reason",
  "Holder",
  "Company",
  "Supplier",
  "Activities",
  "Attraction",
  "Coordinates",
  "Price",
];

const TABLE_PRIMARY_KEY = "0";

const subscriptionTypeOptions = [
  { text: "region", value: "region" },
  { text: "silver", value: "silver" },
  { text: "gold", value: "gold" },
  { text: "platinum", value: "platinum" },
];

const DEFAULT_QUERY_PARAMS_STATE = {
  id: "",
  status: "",
  reason: "",
  level: "",
  supplier_search: "",
  city: "",
  client_search: "",
  card_search: "",
  date: getCurrrentDate(),
  date_finish: getFinishedAtDate(),
};

function getCurrrentDate() {
  const currentDate = new Date();
  currentDate.setDate(1);
  return currentDate.toJSON().substring(0, 10);
}

function getFinishedAtDate() {
  const finishedAtDate = new Date();
  finishedAtDate.setMonth(finishedAtDate.getMonth() + 1);
  finishedAtDate.setDate(0);
  return finishedAtDate.toJSON().substring(0, 10);
}

function getAttractionName(item) {
  return "—";
}

function getSupplierCoordinates(item) {
  if (item[isGeoMockedIndex]) {
    return "Show on the map <br><b>× fake</b>";
  }
  return "Show on the map";
}

export default {
  components: {
    BaseInput,
    Button,
    DatePickerInput,
    ErrorBanner,
    SimpleTable,
    SpinnerBrand,
    NewSelectInput,
  },

  setup() {
    const SORTABLE_TABLE_ITEM_CELLS_VALUE_INDEXES_BY_HEADERS = {
      [USER_ROLES.salemanager]: {
        Registered: 3,
      },
      [USER_ROLES.support2manager]: {
        Registered: 3,
      },
    };

    const TABLE_DATA = {
      [USER_ROLES.salemanager]: {
        primaryKey: TABLE_PRIMARY_KEY,
        headers: TABLE_HEADERS_SALES_MANAGER,
        cells: [
          visitIdIndex,
          registrationDateIndex,
          visitStatusIndex,
          visitStatusReasonIndex,
          holderNameIndex,
          companyNameIndex,
          supplierNameIndex,
          getSupplierCoordinates,
          subscriptionLevelIndex,
        ],
        clickableCells: TABLE_CLICKABLE_CELLS,
        actions: TABLE_ACTIONS,
      },
      [USER_ROLES.support2manager]: {
        primaryKey: TABLE_PRIMARY_KEY,
        headers: TABLE_HEADERS_SUPPORT_2_MANAGER,
        cells: [
          visitIdIndex,
          registrationDateIndex,
          visitStatusIndex,
          visitStatusReasonIndex,
          holderNameIndex,
          companyNameIndex,
          supplierNameIndex,
          supplierAttractionActivitiessList,
          getAttractionName,
          getSupplierCoordinates,
          getPriceOfEntrance,
        ],
        clickableCells: TABLE_CLICKABLE_CELLS,
        actions: TABLE_ACTIONS,
      },
    };

    const configStore = computed(() => store.state.config);

    const download = ref(null);
    const visitsList = ref([]);
    const processing = ref(false);
    const exportExcelProcessing = ref(false);
    const errorMessage = ref("");

    const state = reactive({
      filters: {
        id: { text: "Visit ID", value: true },
        status: { text: "Status", value: false },
        reason: { text: "Reason", value: false },
        level: { text: "Subscription", value: false },
        supplier_search: { text: "Supplier", value: false },
        city: { text: "Location", value: false },
        client_search: { text: "Company", value: false },
        card_search: { text: "Holder", value: false },
        date: { text: "From(Date)", value: true },
        date_finish: { text: "To(Date)", value: true },
      },
      pageNum: 1,
      requestQueryParams: {
        ...DEFAULT_QUERY_PARAMS_STATE,
      },

      sortingState: {
        header: "",
        direction: "asc",
      },
    });

    const locationsOptions = computed(() => {
      const locationsList = configStore.value.country.locations;
      if (!locationsList.length) {
        return [{ text: "Failed to load config's locations", value: null, disabled: true }];
      }
      const locationsOptions = locationsList.map((v) => ({ text: v, value: v }));
      return locationsOptions;
    });

    const sortedVisitsList = computed(() => {
      const list = visitsList.value.slice();
      const sortingState = state.sortingState;
      const header = sortingState.header;

      if (header) {
        const indexOfSortingByElementValue = SORTABLE_TABLE_ITEM_CELLS_VALUE_INDEXES_BY_HEADERS[userRole.value][header];
        const isAscSorting = sortingState.direction === "asc";
        const invertionCompareIfDesc = isAscSorting ? 1 : -1;

        list.sort((a, b) => {
          const valA = String(a[indexOfSortingByElementValue]).toLowerCase();
          const valB = String(b[indexOfSortingByElementValue]).toLowerCase();
          const compareResult = valA.localeCompare(valB, undefined, { numeric: true });

          return invertionCompareIfDesc * compareResult;
        });
      }

      return list;
    });

    const isSaleManager = computed(() => store.getters["auth/isSaleManager"]);
    const isSupport2Manager = computed(() => store.getters["auth/isLocalManager"]);
    const country = computed(() => store.getters["auth/country"]);

    const userRole = computed(() => {
      let role = "";
      if (isSupport2Manager.value) {
        role = USER_ROLES.support2manager;
      } else if (isSaleManager.value) {
        role = USER_ROLES.salemanager;
      }

      return role;
    });

    const tableData = computed(() => TABLE_DATA[userRole.value]);

    function disabledDatesBeforeDateFrom(date) {
      date.setDate(date.getDate() + 1);
      const preparedDate = date.toJSON().substring(0, 10);

      return preparedDate < state.requestQueryParams.date;
    }

    function requestQueryParamsToArray() {
      const { requestQueryParams } = state;
      const paramsArray = [];
      for (let paramKey in requestQueryParams) {
        if (requestQueryParams[paramKey]) {
          paramsArray.push({ name: paramKey, value: requestQueryParams[paramKey] });
        }
      }

      return paramsArray;
    }

    function openCompanyView(companyId) {
      router.push({
        name: "search_card_company",
        params: {
          company_id: companyId,
          started_at: state.requestQueryParams.date,
          finished_at: state.requestQueryParams.date_finish,
        },
      });
    }

    function openHolderView(holderId) {
      router.push({
        name: "search_card_card",
        params: {
          card_id: holderId,
          started_at: state.requestQueryParams.date,
          finished_at: state.requestQueryParams.date_finish,
        },
      });
    }

    function openVisitView(visitId) {
      router.push({
        name: "helpdesk_mobile_view",
        params: { visit: `${visitId}` },
      });
    }

    function showOnTheMap(visitId) {
      router.push({
        name: "show_bounds",
        params: { visit_id: visitId },
      });
    }

    function onClickableCellClick(field, item) {
      if (field === companyNameIndex) {
        openCompanyView(item[companyIdIndex]);
      } else if (field === holderNameIndex) {
        openHolderView(item[holderIdIndex]);
      } else if (field === visitIdIndex) {
        openVisitView(item[field]);
      } else if (field === "Show on the map" || field === "Show on the map <br><b>× fake</b>") {
        showOnTheMap(item[visitIdIndex]);
      }
    }

    function onSubscriptionTypeChange(type) {
      state.requestQueryParams.level = type;
    }

    function onActiveFiltersChange(filterKey) {
      if (state.filters[filterKey].value) {
        state.filters[filterKey].value = false;

        return;
      }

      state.filters[filterKey].value = true;
    }

    function onlocationChange(location) {
      state.requestQueryParams.city = location;
    }

    function onSortVisitsByHeader(header) {
      let direction = "asc";

      if (state.sortingState.header === header && state.sortingState.direction === "asc") {
        direction = "desc";
      }

      state.sortingState = {
        header: header,
        direction: direction,
      };
    }

    function openAdditionalInfoModal(visitId) {
      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.visitInfo,
        payload: {
          visitId,
        },
      });
    }

    async function updateVisit(visitInfo) {
      await store.dispatch("helpdesk/manually_update_visit", visitInfo);
      await fetchVisits();
    }

    function openEditVisitModal(visitId) {
      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.updateVisit,
        payload: {
          visitId,
          onAccept: updateVisit,
        },
      });
    }

    async function createVisit(visitInfo) {
      await store.dispatch("helpdesk/manually_create_visit", visitInfo);
      await fetchVisits();
    }

    function openAddVisitModal() {
      store.dispatch(MODAL_ACTION_OPEN, {
        type: MODAL_TYPES.addVisit,
        payload: {
          onAccept: createVisit,
        },
      });
    }

    async function fetchVisits() {
      processing.value = true;

      const tags = requestQueryParamsToArray();
      visitsList.value = await store.dispatch("visit/visit_list", { page: state.pageNum, tags });

      processing.value = false;

      if (download.value.download) {
        removeAttrs(download.value, "href", "download", "target");
      }
    }

    async function fetchConfigs() {
      const configsList = [{ country: [country.value] }];
      await store.dispatch(CONFIG_ACTION_FETCH_CONFIGS, configsList);
    }

    async function fetchInitialData() {
      processing.value = true;
      errorMessage.value = "";

      try {
        await fetchConfigs();
        await fetchVisits();
      } catch (error) {
        const { errorMessage: text } = prepareAxiosErrors(error);
        errorMessage.value = text;
      }

      processing.value = false;
    }

    async function exportExcel() {
      if (download.value.download) {
        download.value.click();

        return;
      }

      try {
        exportExcelProcessing.value = true;
        await store.dispatch("visit/visit_print_download", {
          tags: requestQueryParamsToArray(),
          ahref: download.value,
        });
      } finally {
        exportExcelProcessing.value = false;
      }
    }

    function getPriceOfEntrance(item) {
      return formatCurrency(item[priceOfEntranceIndex]);
    }

    async function resetFilters() {
      state.requestQueryParams = { ...DEFAULT_QUERY_PARAMS_STATE };
      await fetchVisits();
    }

    function resetSorting() {
      state.sortingState = {
        header: "",
        direction: "asc",
      };
    }

    watch(
      () => sortedVisitsList.value.length,
      () => resetSorting()
    );

    onMounted(async () => {
      await fetchInitialData();
    });

    return {
      TRANSLATABLE_TABLE_CELLS,
      SORTABLE_TABLE_HEADERS,
      subscriptionTypeOptions,

      locationsOptions,
      sortedVisitsList,
      exportExcelProcessing,
      processing,
      download,

      errorMessage,

      state,

      tableData,

      onClickableCellClick,
      onSubscriptionTypeChange,
      onlocationChange,
      openAdditionalInfoModal,
      openEditVisitModal,
      fetchVisits,
      getPriceOfEntrance,
      onSortVisitsByHeader,
      exportExcel,
      onActiveFiltersChange,
      resetFilters,
      disabledDatesBeforeDateFrom,
      openAddVisitModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.row-header_controls {
  display: flex;
  gap: 24px;
}
.content {
  .datepicker,
  .select_input {
    width: 178px;
    margin-bottom: 0;
  }

  .controls {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 24px;
  }

  .filters {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    max-width: 1280px;
    gap: 24px;

    .search_input {
      width: 178px;
    }
  }
}
</style>
